import React, { useEffect, useState } from "react";
import MainButton from "../../../../components/common/MainButton/MainButton";
import Popup from "../../../../components/popup/Popup";
import AddCardForm from "./AddCardForm";
import card from "../../../../assets/img/payment/master-card-dark.jpeg";
import CardDetails from "./CardDetails";

const AddNew = () => {
  const [addMethod, setAddMethod] = useState(false);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  useEffect(() => {
    if (addMethod) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [addMethod]);
  return (
    <>
      <div className="flex gap-8 p-5 border border-mediumgray rounded-3xl w-full bg-white lg:bg-transparent">
        <div className="hidden sm:block w-20 h-10 sm:w-[170px] sm:h-[130px] md:w-[200px] md:h-[130px] xl:w-[280px] xl:h-[180px] mt-auto">
          <img src={card} alt="atm-card" className="w-full h-full" />
        </div>
        <div className="flex flex-col justify-between flex-1">
          <div className="flex items-center justify-between sm:justify-end mb-8 sm:mb-0">
           <div className="w-[120px] h-[70px] sm:hidden">
           <img src={card} alt="atm-card" className="w-full h-full" />
           </div>
            <MainButton
              variant={"small"}
              onClick={() => setAddMethod(!addMethod)}
            >
              Add new
            </MainButton>
          </div>
          <CardDetails />
        </div>
      </div>

      {addMethod && (
        <>
          <Popup
            setTrigger={() => setAddMethod(false)}
            ShowCrossButton={ShowCrossButton}
            // popupWidth={"w-[350px] h-[500px] xs:w-[400px] sm:w-[600px] sm:h-[550px] 2xl:w-[600px] 2xl:h-[550px] pb-10"}
            //   crossPosition={
            //     "flex justify-end w-[16px] absolute left-[320px] xs:left-[370px] sm:left-[565px] 2xl:left-[515px] top-3"
            //   }
          >
            <AddCardForm
              secondMainHeading={"Card updated successfully!"}
              secondP={
                "Your payment details have been securely updated. All future transactions are now seamless, ensuring uninterrupted service for your business. Thank you for keeping your account current with Trades Poster."
              }
              closePopup={() => setAddMethod(false)}
            />
          </Popup>
        </>
      )}
    </>
  );
};

export default AddNew;
