import React from "react";
import SpecialText from "../Typography/SpecialText";
import { Rating } from "primereact/rating";
import Profile from "../../../assets/img/User.png";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import MainButton from "../MainButton/MainButton";
import Title from "../Title/Title";
import AuxiliaryText from "../Typography/AuxiliaryText";
import Pro from "../Tags/Pro";
import useAuth from "../../../Hooks/useAuth";

const ProfileComponent = ({
  actions,
  reporting,
  link,
  heading,
  hoverView,
  profileCompleteion,
  dashboardActions,
}) => {
  const [image, setImage] = useState(Profile);
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);

      // Generate a preview URL for the selected image
      const reader = new FileReader();
      reader.onload = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      setImage(URL.createObjectURL(event.target.files[0]));
    }
  };
  const location = useLocation();
  const { auth } = useAuth();
  const completion = 50;
  return (
    <>
      <div>
        <div className="flex items-center justify-center md:justify-between">
          <div className="flex justify-center md:justify-start group relative size-[72px]">
            <div className="w-full h-[72px] relative">
              <div
                className={
                  location.pathname.includes("dashboard")
                    ? "flex items-center justify-center md:justify-start md:gap-3"
                    : "flex items-center justify-center md:justify-start md:gap-3"
                }
              >
                <div className="relative size-[72px]">
                  {selectedFile && (
                    <img
                      className="object-center rounded-full w-full h-full cursor-pointer"
                      src={imagePreview || URL.createObjectURL(selectedFile)}
                      // src={
                      //   !companyLogo || companyLogo === "null"
                      //     ? require("../../../assets/img/hero-vector.svg").default
                      //     : companyLogo
                      // }
                      alt={"user"}
                      // onError={(e) => {
                      //     e.target.src = require("../../assets/img/default-image.jpg");
                      //   }}
                    />
                  )}
                  {!selectedFile && (
                    <img
                      className="object-center rounded-full w-full h-full cursor-pointer"
                      src={require("../../../assets/img/User.png")}
                      // src={
                      //   !companyLogo || companyLogo === "null"
                      //     ? require("../../../assets/img/hero-vector.svg").default
                      //     : companyLogo
                      // }
                      alt={"user"}
                      // onError={(e) => {
                      //     e.target.src = require("../../assets/img/default-image.jpg");
                      //   }}
                    />
                  )}
                </div>
              </div>
            </div>
            {auth?.user && (
              <div className="hidden group-hover:block absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                <div className="flex justify-center items-center bg-white rounded-full w-[30px] h-[30px] relative">
                  <div>
                    <input
                      type="file"
                      accept="image/*" // Accept only image files
                      onChange={handleFileInputChange}
                      id="profilePicture"
                      className="cursor-pointer hidden"
                    />
                    <label
                      role="button"
                      htmlFor="profilePicture"
                      className="flex justify-center bg-transparent"
                    >
                      <Title text={"Edit"} width={"50"}>
                        <i className="fi fi-rs-pen-circle text-xs text-mediumgray hover:text-primaryblue"></i>
                      </Title>
                    </label>
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* progress performance */}
          {profileCompleteion && (
            <div className="hidden lg:block">
              <div className="flex items-center gap-2">
                <AuxiliaryText variant={"FieldLabel"}>
                  {completion}%
                </AuxiliaryText>
                <div className="w-[90px] xl:w-[100px] h-2 bg-pastalblue rounded-full">
                  <div
                    className={`h-2 bg-freshgreen rounded-full w-[${completion}px]`}
                  ></div>
                </div>
              </div>
              <Link to={"/business-dashboard/settings/account"}>
                <AuxiliaryText
                  variant={"Placeholder"}
                  className={"text-primaryblue"}
                >
                  Complete your profile
                </AuxiliaryText>
              </Link>
            </div>
          )}
        </div>
        <div className="mt-5 text-center md:text-start">
          <div className="group/card lg:w-fit">
            {hoverView && (
              <div className="hidden lg:group-hover/card:block absolute z-20">
                <div
                  className={`bg-white -ml-28 sm:-ml-24 lg:ml-[90px] -mt-[210px] w-[350px] sm:w-[400px] h-[200px] rounded-xl p-5`}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <div className="relative w-[48px] h-[48px]">
                        <img
                          className="object-center rounded-full w-[48px] h-[48px] cursor-pointer"
                          src={require("../../../assets/img/User.png")}
                          // src={
                          //   !companyLogo || companyLogo === "null"
                          //     ? require("../../../assets/img/hero-vector.svg").default
                          //     : companyLogo
                          // }
                          alt={"no-profile"}
                          // onError={(e) => {
                          //     e.target.src = require("../../assets/img/default-image.jpg");
                          //   }}
                        />
                        <div className="absolute -mt-3 left-[30%] mr-2">
                          <Pro className={"bg-primaryblue text-white"} />
                          {/* <div className="bg-primaryblue text-white px-[2px] py-[1px] mt-[1px] mr-2">
                            <AuxiliaryText variant={"Protag"}>
                              Pro
                            </AuxiliaryText>
                          </div> */}
                        </div>
                      </div>
                      <div className="ml-3">
                        <div>
                          <SpecialText variant={"ProfileName"}>
                            Architecture
                            {/* {heading} */}
                          </SpecialText>
                        </div>
                        <div>
                          <SpecialText variant={"OccupationLocation"}>
                            Duffy, ACT, 2611
                          </SpecialText>
                        </div>
                      </div>
                    </div>
                    <div>
                      <MainButton variant={"outlineextrasmall"}>
                        Follow
                      </MainButton>
                    </div>
                  </div>
                  <div className="flex items-center justify-between mt-5">
                    <div className="w-[100px] sm:w-[109px] h-[82px]">
                      <img
                        src={require("../../../assets/img/default-image.jpg")}
                        className="w-full h-full rounded-xl object-center"
                        alt="portfolio"
                      />
                    </div>
                    <div className="w-[100px] sm:w-[109px] h-[82px]">
                      <img
                        src={require("../../../assets/img/default-image.jpg")}
                        className="w-full h-full rounded-xl object-center"
                        alt="portfolio"
                      />
                    </div>
                    <div className="w-[100px] sm:w-[109px] h-[82px]">
                      <img
                        src={require("../../../assets/img/default-image.jpg")}
                        className="w-full h-full rounded-xl object-center"
                        alt="portfolio"
                      />
                    </div>
                  </div>
                </div>
                <div className="ml-[100px] w-0 h-0 border-l-[10px] border-l-transparent border-t-[15px] border-t-white border-r-[10px] border-r-transparent"></div>
              </div>
            )}
            <div className="md:w-fit">
              <Link to={link}>
                <SpecialText
                  variant={"ProfileName"}
                  className={"hover:text-primaryblue text-center"}
                >
                  {heading}
                </SpecialText>
              </Link>
            </div>
          </div>
          <div className="mt-3">
            <SpecialText variant={"OccupationLocation"}>
              Waterproofing company
            </SpecialText>
          </div>
          <div className="mt-1">
            <SpecialText variant={"OccupationLocation"}>
              Duffy, Australian Capital Territory, 2611
            </SpecialText>
          </div>
          <div className="mt-1">
            <SpecialText variant={"Date"}>
              Member since Dec&nbsp;2007
            </SpecialText>
          </div>

          {!actions && (
            <div className="flex items-center justify-center md:justify-start mt-3">
              <div className="pr-3">
                <Title
                  text={"Report this business"}
                  width={"150"}
                  className={
                    location.pathname.includes("SellerDashboard")
                      ? "bg-pastalblue"
                      : "bg-white"
                  }
                >
                  <div>
                    <i
                      className="fi fi-rs-flag-alt text-xs cursor-pointer hover:text-alertred"
                      onClick={reporting}
                    ></i>
                  </div>
                </Title>
              </div>
              <div>
                <Title
                  text={"Share"}
                  width={"50"}
                  className={
                    location.pathname.includes("SellerDashboard")
                      ? "bg-pastalblue"
                      : "bg-white"
                  }
                >
                  <div className="flex items-center px-3 border-l border-l-mediumgray">
                    <div>
                      <i className="fi fi-rs-share text-xs cursor-pointer mr-1 "></i>
                    </div>
                    <SpecialText variant={"Counter"} className={"ml-1"}>
                      1k
                    </SpecialText>
                  </div>
                </Title>
              </div>
              <div>
                <Title
                  text={"Views"}
                  width={"50"}
                  className={
                    location.pathname.includes("SellerDashboard")
                      ? "bg-pastalblue"
                      : "bg-white"
                  }
                >
                  <div className="flex items-center border-l border-l-mediumgray border-r border-r-mediumgray  px-3">
                    <div>
                      <i className="fi fi-rs-eye text-xs cursor-pointer mr-1"></i>
                    </div>
                    <SpecialText variant={"Counter"} className={"ml-1"}>
                      1k
                    </SpecialText>
                  </div>
                </Title>
              </div>
              <div>
                <Title
                  text={"Rating"}
                  width={"50"}
                  className={
                    location.pathname.includes("SellerDashboard")
                      ? "bg-pastalblue"
                      : "bg-white"
                  }
                >
                  <div className="flex items-center pl-3">
                    <div>
                      <Rating
                        value={3}
                        stars={1}
                        style={{ gap: 5 }}
                        cancel={false}
                        defaultValue={4}
                      />
                    </div>
                    <SpecialText variant={"Counter"} className={"ml-1"}>
                      4.9
                    </SpecialText>
                  </div>
                </Title>
              </div>
            </div>
          )}
          {dashboardActions && (
            <div className="flex items-center mt-3">
              <div>
                <Title
                  text={"Share"}
                  width={"50"}
                  className={
                    location.pathname.includes("SellerDashboard")
                      ? "bg-pastalblue"
                      : "bg-white"
                  }
                >
                  <div className="flex items-center pr-3">
                    <div>
                      <i className="fi fi-rs-share text-xs cursor-pointer mr-1 "></i>
                    </div>
                    <SpecialText variant={"Counter"} className={"ml-1"}>
                      1k
                    </SpecialText>
                  </div>
                </Title>
              </div>
              <div>
                <Title
                  text={"Views"}
                  width={"50"}
                  className={
                    location.pathname.includes("SellerDashboard")
                      ? "bg-pastalblue"
                      : "bg-white"
                  }
                >
                  <div className="flex items-center border-l border-l-mediumgray border-r border-r-mediumgray px-3">
                    <div>
                      <i className="fi fi-rs-eye text-xs cursor-pointer mr-1"></i>
                    </div>
                    <SpecialText variant={"Counter"} className={"ml-1"}>
                      1k
                    </SpecialText>
                  </div>
                </Title>
              </div>
              <div>
                <Title
                  text={"Rating"}
                  width={"50"}
                  className={
                    location.pathname.includes("SellerDashboard")
                      ? "bg-pastalblue"
                      : "bg-white"
                  }
                >
                  <div className="flex items-center pl-3">
                    <div>
                      <Rating
                        value={3}
                        stars={1}
                        style={{ gap: 5 }}
                        cancel={false}
                        defaultValue={4}
                      />
                    </div>
                    <SpecialText variant={"Counter"} className={"ml-1"}>
                      4.9
                    </SpecialText>
                  </div>
                </Title>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ProfileComponent;
