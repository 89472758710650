import React from "react";
import Heading from "../../components/common/Typography/Heading";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";
import { useParams } from "react-router-dom";

const CheckList = () => {
  const { slug } = useParams();
  const data = [
    {
      slug: "architectural-services",
      heading: "Checklist for hiring an architect",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify registration and licensing.",
        },
        {
          heading: "Review portfolio",
          paragraph: "Look at past projects for style and quality.",
        },
        {
          heading: "Get quotes",
          paragraph: "Ask for detailed, itemized estimates.",
        },
        {
          heading: "Ensure compliance",
          paragraph: "Must meet AS 1428 and AS/NZS 1170 standards.",
        },
        {
          heading: "Discuss design process",
          paragraph: "Understand their approach and timelines.",
        },
        {
          heading: "Read reviews",
          paragraph: "Check feedback from past clients.",
        },
      ],
    },
    {
      slug: "air-conditioning-and-heating",
      heading: "Checklist for hiring an HVAC expert",
      list: [
        {
          heading: "Check license",
          paragraph: "Ensure it's valid.",
        },
        {
          heading: "Skill expertise",
          paragraph: "Confirm knowledge in systems like ducted or split.",
        },
        {
          heading: "Get quotes",
          paragraph: "Ask for clear, itemized costs.",
        },
        {
          heading: "Check compliance",
          paragraph: "Must meet AS/NZS 1668.2 and AS/NZS 3000 standards.",
        },
        {
          heading: "Review certifications",
          paragraph: "Verify HVAC-specific qualifications.",
        },
        {
          heading: "Ask about efficiency",
          paragraph: "Look for energy-saving features like smart thermostats.",
        },
        {
          heading: "Confirm warranties",
          paragraph: "Check coverage for parts and labour.",
        },
        {
          heading: "Read reviews",
          paragraph: "Assess reliability through customer feedback.",
        },
      ],
    },
    {
      slug: "bricklaying-and-blockwork",
      heading: "Checklist for hiring a bricklayer",
      list: [
        {
          heading: "Check licensing",
          paragraph: "Ensure the bricklayer is licensed and certified.",
        },
        {
          heading: "Review specializations",
          paragraph:
            "Confirm expertise in areas like repointing or restoration.",
        },
        {
          heading: "Get quotes",
          paragraph:
            "Request clear, itemized estimates for accurate budgeting.",
        },
        {
          heading: "Confirm compliance",
          paragraph: "Verify compliance with AS 3700 standards.",
        },
        {
          heading: "Check certifications",
          paragraph: "Look for specific skills in residential brickwork.",
        },
        {
          heading: "Check references",
          paragraph: "Review customer feedback for reliability.",
        },
      ],
    },
    {
      slug: "cabinet-making",
      heading: "Checklist for hiring a cabinet maker",
      list: [
        {
          heading: "Check license",
          paragraph: "Confirm trade license and qualifications.",
        },
        {
          heading: "Review portfolio",
          paragraph: "Look at past projects for design and finishes.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized estimates based on cabinetry type.",
        },
        {
          heading: "Ensure compliance",
          paragraph: "Must follow AS 4386 standards for kitchen cabinets.",
        },
        {
          heading: "Discuss materials",
          paragraph: "Ensure quality materials fit your needs.",
        },
        {
          heading: "Ask about customization",
          paragraph: "Check if they offer tailored design options.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look at feedback for reliability and craftsmanship.",
        },
      ],
    },
    {
      slug: "carpentry",
      heading: "Checklist for hiring a carpenter",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify qualifications and licensing.",
        },
        {
          heading: "Past work",
          paragraph: "Look at previous projects for quality and style.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized estimates based on the project scope.",
        },
        {
          heading: "Ensure compliance",
          paragraph: "Must follow AS 1684 standards for timber frames.",
        },
        {
          heading: "Discuss materials",
          paragraph: "Confirm use of quality, durable materials.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look at feedback for reliability and craftsmanship.",
        },
      ],
    },
    {
      slug: "carpet-and-flooring",
      heading: "Checklist for hiring a flooring specialist",
      list: [
        {
          heading: "Check license",
          paragraph: "Confirm credentials and trade license.",
        },
        {
          heading: "Portfolio",
          paragraph: "Examine past projects for quality and range.",
        },
        {
          heading: "Get quotes",
          paragraph:
            "Request itemized estimates based on flooring type and size.",
        },
        {
          heading: "Ensure compliance",
          paragraph: "Must meet AS 1884, AS/NZS 4586, and AS 3958 standards.",
        },
        {
          heading: "Discuss materials",
          paragraph:
            "Choose materials for durability, maintenance, and design.",
        },
        {
          heading: "Ask about installation time",
          paragraph: "Confirm estimated duration of the project.",
        },
        {
          heading: "Read reviews",
          paragraph: "Check feedback for reliability and service quality.",
        },
      ],
    },
    {
      slug: "caulking-services",
      heading: "Checklist for hiring a caulking specialist",
      list: [
        {
          heading: "Review work",
          paragraph: "Look for neatness and quality.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized estimates.",
        },
        {
          heading: "Discuss materials",
          paragraph: "Confirm use of quality caulk.",
        },
        {
          heading: "Check experience",
          paragraph: "Look for interior and exterior skills.",
        },
        {
          heading: "Read reviews",
          paragraph: "Assess reliability and craftsmanship.",
        },
      ],
    },
    {
      slug: "cleaning-services",
      heading: "Checklist for hiring a cleaning specialist",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify proper licensing.",
        },
        {
          heading: "Review services",
          paragraph: "Confirm they offer the cleaning you need.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized pricing.",
        },
        {
          heading: "Ask about products",
          paragraph: "Ensure safe, effective cleaners are used.",
        },
        {
          heading: "Check experience",
          paragraph: "Look for residential or commercial expertise.",
        },
        {
          heading: "Check insurance",
          paragraph: "Ensure liability coverage.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look at feedback for reliability.",
        },
      ],
    },
    {
      slug: "concrete-and-paving",
      heading: "Checklist for hiring a concrete & paving specialist",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify trade license and qualifications.",
        },
        {
          heading: "Review work",
          paragraph: "Look at past projects for quality and finish.",
        },
        {
          heading: "Get quotes",
          paragraph: "Ask for itemized costs based on scope.",
        },
        {
          heading: "Ensure compliance",
          paragraph: "Must follow AS 3600 and AS 3727 standards.",
        },
        {
          heading: "Discuss materials",
          paragraph: "Choose durable, design-friendly options.",
        },
        {
          heading: "Check experience",
          paragraph: "Ensure expertise in both concrete and paving.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look at feedback for reliability and punctuality.",
        },
      ],
    },
    {
      slug: "conveyancing",
      heading: "Checklist for hiring a conveyancer",
      list: [
        {
          heading: "Check license",
          paragraph: "Confirm registration and qualifications.",
        },
        {
          heading: "Review experience",
          paragraph: "Look for residential or commercial expertise.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized fees and costs.",
        },
        {
          heading: "Check communication",
          paragraph: "Ensure clear and timely updates.",
        },
        {
          heading: "Discuss services",
          paragraph: "Confirm contract review and settlement help.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look at feedback for accuracy and reliability.",
        },
      ],
    },
    {
      slug: "curtains-blinds-and-awnings",
      heading: "Checklist for hiring a window covering specialist",
      list: [
        {
          heading: "Check license",
          paragraph: "Confirm credentials and licenses.",
        },
        {
          heading: "Review work",
          paragraph: "Look at past projects for style and quality.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized pricing by covering type.",
        },
        {
          heading: "Ensure compliance",
          paragraph: "Must meet AS 2663 and AS 4055 standards.",
        },
        {
          heading: "Check experience",
          paragraph: "Look for skills in internal and external installations.",
        },
        {
          heading: "Discuss materials",
          paragraph: "Ensure quality fabric or materials.",
        },
        {
          heading: "Read reviews",
          paragraph: "Assess service reliability and quality.",
        },
      ],
    },
    {
      slug: "decking-gazebo-and-carports",
      heading: "Checklist for hiring a decking & outdoor structures specialist",
      list: [
        {
          heading: "Check license",
          paragraph: "Confirm qualifications.",
        },
        {
          heading: "Review work",
          paragraph: "Look at design and build quality.",
        },
        {
          heading: "Get quotes",
          paragraph: "Ask for itemized costs.",
        },
        {
          heading: "Ensure compliance",
          paragraph: "Must follow AS 1684.",
        },
        {
          heading: "Discuss materials",
          paragraph: "Choose durable options.",
        },
        {
          heading: "Check experience",
          paragraph: "Look for decking, gazebo, or veranda expertise.",
        },
        {
          heading: "Ask about maintenance",
          paragraph: "Confirm care needs.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look for reliable feedback.",
        },
      ],
    },
    {
      slug: "demolition-and-excavation",
      heading: "Checklist for hiring a demolition & excavation specialist",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify credentials.",
        },
        {
          heading: "Ensure safety",
          paragraph: "Confirm protocols are followed.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized costs.",
        },
        {
          heading: "Check compliance",
          paragraph: "Must meet AS 2601 and AS 3798.",
        },
        {
          heading: "Discuss waste",
          paragraph: "Confirm disposal plans.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look for reliable feedback.",
        },
      ],
    },
    {
      slug: "electrical-services",
      heading: "Checklist for hiring an electrician",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify trade license and certifications.",
        },
        {
          heading: "Review experience",
          paragraph: "Look for relevant project experience.",
        },
        {
          heading: "Get quotes",
          paragraph: "Ask for itemized costs.",
        },
        {
          heading: "Ensure compliance",
          paragraph: "Must meet AS/NZS 3000 standards.",
        },
        {
          heading: "Discuss warranty",
          paragraph: "Confirm parts and labor coverage.",
        },
        {
          heading: "Read reviews",
          paragraph: "Check feedback for reliability.",
        },
      ],
    },
    {
      slug: "engineering-services",
      heading: "Checklist for hiring an engineer",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify professional registration.",
        },
        {
          heading: "Review specialization",
          paragraph: "Ensure expertise in the needed field.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request detailed costs and scope.",
        },
        {
          heading: "Ensure compliance",
          paragraph: "Must meet AS 3600 and AS/NZS 3000.",
        },
        {
          heading: "Check experience",
          paragraph: "Look for similar projects.",
        },
        {
          heading: "Read reviews",
          paragraph: "Check feedback and success rates.",
        },
      ],
    },
    {
      slug: "fencing-and-gates",
      heading: "Checklist for hiring a fencing & gate specialist",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify trade license and experience.",
        },
        {
          heading: "Review materials",
          paragraph: "Choose durable, aesthetic options.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized costs for type and size.",
        },
        {
          heading: "Ensure compliance",
          paragraph: "Must meet AS 1926.1 and AS 1725.",
        },
        {
          heading: "Discuss design",
          paragraph: "Consider custom options and automation.",
        },
        {
          heading: "Check experience",
          paragraph: "Look for past projects in similar setups.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look at feedback for craftsmanship and punctuality.",
        },
      ],
    },
    {
      slug: "glass-and-glazing",
      heading: "Checklist for hiring a glazier & screen specialist",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify trade license and experience.",
        },
        {
          heading: "Review materials",
          paragraph: "Choose glass for safety and aesthetics.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized costs for the project scope.",
        },
        {
          heading: "Ensure compliance",
          paragraph: "Must meet AS 1288 and AS 3740 standards.",
        },
        {
          heading: "Discuss customization",
          paragraph: "Consider cutting, tinting, or custom designs.",
        },
        {
          heading: "Check experience",
          paragraph: "Look for past projects with similar installations.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look at feedback for quality and punctuality.",
        },
      ],
    },
    {
      slug: "garden-and-landscaping",
      heading: "Checklist for hiring a landscaper",
      list: [
        {
          heading: "Check license",
          paragraph: "Confirm qualifications and experience.",
        },
        {
          heading: "Review portfolio",
          paragraph: "Ensure their style matches your vision.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized costs for design and materials.",
        },
        {
          heading: "Discuss materials",
          paragraph: "Select plants and hardscapes suited to climate.",
        },
        {
          heading: "Set timeline",
          paragraph: "Agree on start and completion dates.",
        },
        {
          heading: "Read reviews",
          paragraph: "Check feedback for creativity and quality.",
        },
      ],
    },
    {
      slug: "handyman-services",
      heading: "Checklist for hiring a handyman",
      list: [
        {
          heading: "Check license",
          paragraph: "Confirm trade license and qualifications.",
        },
        {
          heading: "Review services",
          paragraph: "Ensure needed repairs are offered.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized costs.",
        },
        {
          heading: "Discuss timing",
          paragraph: "Confirm availability and completion time.",
        },
        {
          heading: "Check insurance",
          paragraph: "Verify liability coverage.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look for feedback on reliability and quality.",
        },
      ],
    },
    {
      slug: "inspections-and-surveyors",
      heading: "Checklist for hiring an inspector or surveyor",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify license and qualifications.",
        },
        {
          heading: "Review services",
          paragraph: "Confirm they offer needed inspections.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized costs for the job.",
        },
        {
          heading: "Discuss timing",
          paragraph: "Confirm availability and duration.",
        },
        {
          heading: "Check insurance",
          paragraph: "Ensure liability coverage.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look at feedback for accuracy and reliability.",
        },
      ],
    },
    {
      slug: "security-and-automation",
      heading: "Checklist for hiring a security & automation specialist",
      list: [
        {
          heading: "Check license",
          paragraph: "Confirm credentials and security licenses.",
        },
        {
          heading: "Review work",
          paragraph: "Look at past installations for quality.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized pricing based on needs.",
        },
        {
          heading: "Ensure compliance",
          paragraph: "Must meet AS 2201 and AS/NZS 3000 standards.",
        },
        {
          heading: "Discuss systems",
          paragraph: "Explore options fitting your budget.",
        },
        {
          heading: "Check support",
          paragraph: "Ensure ongoing service and assistance.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look at feedback for reliability.",
        },
      ],
    },
    {
      slug: "insulation-services",

      heading: "Checklist for hiring an insulation specialist",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify trade license and qualifications.",
        },
        {
          heading: "Review materials",
          paragraph: "Choose insulation for energy, fire, or sound needs.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized costs by type and size.",
        },
        {
          heading: "Ensure compliance",
          paragraph: "Must meet AS 4859.1 standards.",
        },
        {
          heading: "Discuss safety",
          paragraph: "Confirm safety for spray foam or foil use.",
        },
        {
          heading: "Check experience",
          paragraph: "Look for past insulation projects.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look for feedback on workmanship and reliability.",
        },
      ],
    },
    {
      slug: "interior-design",
      heading: "Checklist for hiring an interior designer",
      list: [
        {
          heading: "Check license",
          paragraph: "Confirm qualifications and certifications.",
        },
        {
          heading: "Review portfolio",
          paragraph: "Ensure their style matches your vision.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized design costs.",
        },
        {
          heading: "Discuss style",
          paragraph: "Share your preferences and needs.",
        },
        {
          heading: "Set timeline",
          paragraph: "Agree on design stages and deadlines.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look for feedback on creativity and reliability.",
        },
      ],
    },
    {
      slug: "locksmiths",
      heading: "Checklist for hiring a locksmith",
      list: [
        {
          heading: "Check license",
          paragraph: "Confirm license and qualifications.",
        },
        {
          heading: "Review services",
          paragraph: "Ensure needed services are offered.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized costs for locks and labour.",
        },
        {
          heading: "Ensure compliance",
          paragraph: "Must meet AS 4145 standards.",
        },
        {
          heading: "Discuss security",
          paragraph: "Explore digital or high-security options.",
        },
        {
          heading: "Check experience",
          paragraph: "Look for expertise in similar projects.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look at feedback for reliability and response times.",
        },
      ],
    },
    {
      slug: "painting-and-decorating",
      heading: "Checklist for hiring a painter & decorator",
      list: [
        {
          heading: "Check license",
          paragraph: "Confirm license and experience.",
        },
        {
          heading: "Review portfolio",
          paragraph: "Ensure style matches your vision.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized costs for labour and materials.",
        },
        {
          heading: "Discuss colors",
          paragraph: "Explore options for colors and finishes.",
        },
        {
          heading: "Set timeline",
          paragraph: "Agree on prep, painting, and drying phases.",
        },
        {
          heading: "Check materials",
          paragraph: "Ensure quality paint is used.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look for feedback on reliability and quality.",
        },
      ],
    },
    {
      slug: "moving-and-delivery",
      heading: "Checklist for hiring a mover",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify qualifications and compliance.",
        },
        {
          heading: "Review services",
          paragraph: "Choose local, interstate, or storage options.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized costs, including packing or storage.",
        },
        {
          heading: "Discuss insurance",
          paragraph: "Ensure coverage for items in transit.",
        },
        {
          heading: "Set timeline",
          paragraph: "Agree on packing, moving, and delivery dates.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look for feedback on service quality and punctuality.",
        },
      ],
    },
    {
      slug: "mould-and-asbestos",
      heading: "Checklist for hiring a mould & asbestos specialist",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify qualifications and compliance.",
        },
        {
          heading: "Review services",
          paragraph: "Ensure full testing, removal, and disposal.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized costs for all services.",
        },
        {
          heading: "Discuss safety",
          paragraph: "Confirm strict safety measures.",
        },
        {
          heading: "Set timeline",
          paragraph: "Agree on inspection, removal, and clearance dates.",
        },
        {
          heading: "Check experience",
          paragraph: "Look for similar past projects.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look for feedback on professionalism and safety.",
        },
      ],
    },
    {
      slug: "pest-control",
      heading: "Checklist for hiring a pest control specialist",
      list: [
        {
          heading: "Check license",
          paragraph: "Confirm qualifications and experience.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized costs for inspection and treatment.",
        },
        {
          heading: "Discuss safety",
          paragraph: "Confirm safety for kids, pets, and environment.",
        },
        {
          heading: "Ensure compliance",
          paragraph: "Must meet AS 3660.1 and AS/NZS 4341.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look for feedback on effectiveness and safety.",
        },
      ],
    },
    {
      slug: "solid-plastering",
      heading: "Checklist for hiring a plasterer",
      list: [
        {
          heading: "Check license",
          paragraph: "Confirm license and experience.",
        },
        {
          heading: "Review work",
          paragraph: "Look at past projects for quality.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized costs for materials and labour.",
        },
        {
          heading: "Discuss techniques",
          paragraph: "Explore skim coating or decorative options.",
        },
        {
          heading: "Set timeline",
          paragraph: "Agree on prep, plastering, and drying times.",
        },
        {
          heading: "Check materials",
          paragraph: "Ensure quality plaster is used.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look for feedback on reliability and work quality.",
        },
      ],
    },
    {
      slug: "plumbing",
      heading: "Checklist for hiring a plumber",
      list: [
        {
          heading: "Check licensing",
          paragraph: "Valid license for plumbing work.",
        },
        {
          heading: "Review specializations",
          paragraph: "Expertise in needed services (e.g., gas fitting).",
        },
        {
          heading: "Get quotes",
          paragraph: "Detailed, itemized quotes for costs.",
        },
        {
          heading: "Confirm compliance",
          paragraph: "Adheres to AS/NZS 3500 standards.",
        },
        {
          heading: "Check certifications",
          paragraph: "Look for relevant qualifications (e.g., AS/NZS 5601).",
        },
        {
          heading: "Check references",
          paragraph: "Ask for customer reviews.",
        },
      ],
    },
    {
      slug: "pools-and-spas",
      heading: "Pool & spa checklist",
      list: [
        {
          heading: "Verify licensing",
          paragraph: "Check certification and insurance.",
        },
        {
          heading: "Review work",
          paragraph: "Look at recent projects.",
        },
        {
          heading: "Request quote",
          paragraph: "Ask for itemized costs.",
        },
        {
          heading: "Confirm compliance",
          paragraph: "Ensure safety code adherence.",
        },
      ],
    },
    {
      slug: "rendering",
      heading: "Tips for choosing a renderer",
      list: [
        {
          heading: "Check credentials",
          paragraph: "Ensure they’re licensed and certified.",
        },
        {
          heading: "Review past work",
          paragraph: "Assess quality from previous projects.",
        },
        {
          heading: "Get detailed quotes",
          paragraph: "Request itemized costs.",
        },
        {
          heading: "Discuss finishes",
          paragraph: "Choose smooth, textured, or colored options.",
        },
        {
          heading: "Set clear timelines",
          paragraph: "Agree on prep, application, and drying.",
        },
        {
          heading: "Confirm material quality",
          paragraph: "Ensure durable materials are used.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look for feedback on reliability and results.",
        },
      ],
    },
    {
      slug: "renovation-and-restoration",
      heading: "Checklist for hiring a renovation & restoration specialist",
      list: [
        {
          heading: "License check",
          paragraph: "Verify credentials.",
        },
        {
          heading: "Work review",
          paragraph: "Assess past projects.",
        },
        {
          heading: "Quote request",
          paragraph: "Get itemized costs.",
        },
        {
          heading: "Design agreement",
          paragraph: "Confirm materials and plans.",
        },
        {
          heading: "Timeline set",
          paragraph: "Agree on deadlines.",
        },
        {
          heading: "Review feedback",
          paragraph: "Check quality and reliability.",
        },
      ],
    },
    {
      slug: "roofing",
      heading: "Checklist for hiring a roofer",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify roofing qualifications and experience.",
        },
        {
          heading: "Review work",
          paragraph: "Assess past projects for quality.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized pricing.",
        },
        {
          heading: "Discuss materials",
          paragraph: "Confirm choice of roofing materials.",
        },
        {
          heading: "Read reviews",
          paragraph: "Check for reliability and quality feedback.",
        },
      ],
    },
    {
      slug: "scaffolding",
      heading: "Checklist for hiring a scaffolder",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify qualifications.",
        },
        {
          heading: "Review equipment",
          paragraph: "Ensure quality and stability.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized costs.",
        },
        {
          heading: "Read reviews",
          paragraph: "Look for reliability feedback.",
        },
      ],
    },
    {
      slug: "shopfitting",
      heading: "Checklist for hiring a shopfitter",
      list: [
        {
          heading: "License verification",
          paragraph: "Confirm qualifications.",
        },
        {
          heading: "Work review",
          paragraph: "Check project alignment with your vision.",
        },
        {
          heading: "Quotes",
          paragraph: "Request detailed pricing.",
        },
        {
          heading: "Design agreement",
          paragraph: "Finalize layouts and custom needs.",
        },
        {
          heading: "Timeline",
          paragraph: "Set clear project milestones.",
        },
        {
          heading: "Material check",
          paragraph: "Ensure durability.",
        },
        {
          heading: "Reviews",
          paragraph: "Look for reliability feedback.",
        },
      ],
    },
    {
      slug: "skip-bins",
      heading: "Checklist for hiring a skip bin service",
      list: [
        {
          heading: "Review bins",
          paragraph: "Select the right size and type.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized costs.",
        },
        {
          heading: "Check waste limits",
          paragraph: "Confirm acceptable waste types.",
        },
        {
          heading: "Set duration",
          paragraph: "Agree on hire and pickup times.",
        },
        {
          heading: "Read reviews",
          paragraph: "Check feedback on reliability.",
        },
      ],
    },
    {
      slug: "solar-power",
      heading: "Checklist for hiring a solar installer",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify CEC accreditation.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request detailed costs.",
        },
        {
          heading: "Explore incentives",
          paragraph: "Check rebates and tariffs.",
        },
        {
          heading: "Set timeline",
          paragraph: "Agree on project schedule.",
        },
        {
          heading: "Check experience",
          paragraph: "Ensure relevant expertise.",
        },
      ],
    },
    {
      slug: "staircase-services",
      heading: "Checklist for hiring a staircase builder",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify qualifications and safety compliance.",
        },
        {
          heading: "Review work",
          paragraph: "Ensure past projects match your style.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request detailed costs.",
        },
        {
          heading: "Discuss design",
          paragraph: "Choose suitable materials.",
        },
        {
          heading: "Set timeline",
          paragraph: "Agree on project phases.",
        },
        {
          heading: "Check experience",
          paragraph: "Ensure expertise in handrails and balustrades.",
        },
        {
          heading: "Read reviews",
          paragraph: "Check feedback on reliability.",
        },
      ],
    },
    {
      slug: "stonework",
      heading: "Checklist for hiring a stone installer",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify qualifications and safety compliance.",
        },
        {
          heading: "Review work",
          paragraph: "Ensure quality aligns with your expectations.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request itemized costs for materials and labour.",
        },
        {
          heading: "Discuss stone",
          paragraph: "Choose the right materials (e.g., granite, sandstone).",
        },
        {
          heading: "Set timeline",
          paragraph: "Agree on project schedule.",
        },
        {
          heading: "Read reviews",
          paragraph: "Check feedback on reliability and craftsmanship.",
        },
      ],
    },
    {
      slug: "tiling",
      heading: "Checklist for hiring a tiler",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify qualifications and compliance.",
        },
        {
          heading: "Review work",
          paragraph: "Ensure style matches your vision.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request detailed costs.",
        },
        {
          heading: "Discuss tiles",
          paragraph: "Choose appropriate types.",
        },
        {
          heading: "Set timeline",
          paragraph: "Agree on schedule.",
        },
        {
          heading: "Check materials",
          paragraph: "Ensure quality and sealing.",
        },
        {
          heading: "Read reviews",
          paragraph: "Verify reliability and quality.",
        },
      ],
    },
    {
      slug: "waterproofing",
      heading: "Checklist for hiring a waterproofers",
      list: [
        {
          heading: "Check license",
          paragraph: "Verify qualifications and compliance.",
        },
        {
          heading: "Review work",
          paragraph: "Assess past projects for quality.",
        },
        {
          heading: "Get quotes",
          paragraph: "Request detailed cost breakdown.",
        },
        {
          heading: "Discuss materials",
          paragraph: "Choose appropriate products.",
        },
        {
          heading: "Set timeline",
          paragraph: "Agree on prep, application, and drying.",
        },
        {
          heading: "Read reviews",
          paragraph: "Check feedback on reliability.",
        },
      ],
    },
  ];
  const categoryData = data.filter((item) => item.slug === slug);
  return (
    <>
      {categoryData &&
        categoryData.map((item) => {
          return (
            <>
              <div className="mx-[5%] xl:mx-auto xl:w-[1075px] 2xl:w-[1440px]  mt-10 sm:mt-40">
                <Heading variant={"h2"} className={"text-offblack"}>
                  {item.heading}
                </Heading>
              </div>
              <div className="mx-[5%] xl:mx-auto h-[1200px] sm:h-[800px] xl:w-[1075px] xl:h-[700px] 2xl:h-[1000px] 2xl:w-[1440px] bg-about bg-no-repeat bg-center bg-cover rounded-3xl mt-10 relative flex items-center">
                <div className="absolute inset-0 p-10 flex justify-center xl:justify-start items-center xl:ml-20">
                  <div className="grid grid-cols-1 sm:grid-cols-2 w-fit gap-5 lg:gap-10 lg:mt-10">
                    {item.list.map((item, index) => {
                      return (
                        <>
                          <div
                            key={index}
                            className="bg-white rounded-xl pl-10 p-5 max-w-[400px] lg:w-[400px] h-fit"
                          >
                            <SecondaryHeading
                              variant={"MainParagraphHeading"}
                              className={"text-offblack font-semibold"}
                            >
                              {item.heading}
                            </SecondaryHeading>
                            <Paragraph
                              variant={"MainParagraph"}
                              className={"text-offblack mt-3"}
                            >
                              {item.paragraph}
                            </Paragraph>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </>
          );
        })}
    </>
  );
};

export default CheckList;
