import React from "react";
import TextFiled from "../../../../components/common/InputField/TextFiled";
import Snippet from "../../../../components/common/SnippetIcon/Snippet";
import { Chips } from "primereact/chips";
import TextArea from "../../../../components/common/InputField/TextArea";
import InteractiveText from "../../../../components/common/Typography/InteractiveText";
import MainButton from "../../../../components/common/MainButton/MainButton";
import { Field, ErrorMessage } from "formik";
import SpecialText from "../../../../components/common/Typography/SpecialText";

const PostDetails = ({ setStep, setFieldValue, values, errors }) => {

  return (
    <>
      <div className="w-fit mx-auto mt-[60px]">
        {/* Post Title Field */}
        <div>
          <Field name="postTitle">
            {({ field, form }) => (
              <div>
                <TextFiled
                  {...field}
                  variant={"large"}
                  label={"Post Title"}
                  className="lg:bg-pastalblue focus:border focus:border-primaryblue"
                  onChange={(e) => setFieldValue("postTitle", e.target.value)}
                  value={values.postTitle}
                  name="postTitle"
                  placeholder="Write post title"
                  star={true}
                />
                <SpecialText
                  variant={"FootNoteDisclaimer"}
                  className={"mt-2 text-alertred"}
                >
                  {form.errors.postTitle}
                </SpecialText>
              </div>
            )}
          </Field>
        </div>

        {/* Keywords Chips Field */}
        <div className="mt-5">
          <Snippet
            variant={"label"}
            label={"Keywords"}
            text={"Use pre-filed keywords or add your own to boost visibility."}
            snippet={true}
            star={true}
          />
          <Chips
            id="addyourtags"
            placeholder="Tag your post with relevant keywords"
            className="custom-chips-bg placeholder-lightgray text-offblack px-2 min-h-[46px] sm:min-h-[50px] md:min-h-[54px] lg:min-h-[56px] w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] rounded-lg"
            value={values.postTags || []} // Assuming `postTags` is in `values`
            onChange={(e) => setFieldValue("postTags", e.value)}
            separator=","
          />
          <SpecialText
            variant={"FootNoteDisclaimer"}
            className={"mt-2 text-alertred"}
          >
            <ErrorMessage name="postTags" component="div" />
          </SpecialText>
        </div>

        {/* Post Description Field */}
        <div className="mt-5">
          <Field name="description">
            {({ field, form }) => (
              <>
                <TextArea
                  {...field}
                  variant={"large"}
                  star={true}
                  className="lg:bg-pastalblue"
                  name="description"
                  label={"Post Description"}
                  placeholder={
                    "Provide a detailed description of your services post"
                  }
                  value={values.description}
                  onChange={(e) => setFieldValue("description", e.target.value)}
                />
                <SpecialText
                  variant={"FootNoteDisclaimer"}
                  className={"mt-2 text-alertred"}
                >
                  {form.errors.description}
                </SpecialText>
              </>
            )}
          </Field>
        </div>
      </div>

      {/* Buttons and navigation */}
      <div className="flex items-center justify-between w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mt-10 mx-auto">
        <div onClick={() => setStep(2)}>
          <InteractiveText
            variant={"ActionLink"}
            className="text-mediumgray hover:text-primaryblue cursor-pointer"
          >
            Back
          </InteractiveText>
        </div>
        <div>
          <MainButton
            type="submit"
            onClick={() => {
              if (values.description && values.postTitle && values.postTags) {
                setStep(4); // Only move to next step if all values are selected
              }
            }}
            variant={"small"}
          >
            Next
          </MainButton>
        </div>
      </div>
    </>
  );
};

export default PostDetails;
