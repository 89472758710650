import React from "react";
import MainButton from "../../../components/common/MainButton/MainButton";
import TextArea from "../../../components/common/InputField/TextArea";
import { useState } from "react";
import TextFiled from "../../../components/common/InputField/TextFiled";
import Paragraph from "../../../components/common/Typography/Paragraph";
import Heading from "../../../components/common/Typography/Heading";
import { Link } from "react-router-dom";
import SecondaryHeading from "../../../components/common/Typography/SecondaryHeading";
import SpecialText from "../../../components/common/Typography/SpecialText";
import AuxiliaryText from "../../../components/common/Typography/AuxiliaryText";
import { Dropdown } from "primereact/dropdown";
import icon from "../../../assets/img/User.png";
import InteractiveText from "../../../components/common/Typography/InteractiveText";
import BubbleButton from "../../../components/common/MainButton/BubbleButton";
import Mobile from "../../../assets/img/getintouch/mobile.svg";
import Office from "../../../assets/img/getintouch/office.svg";
import Website from "../../../assets/img/getintouch/website.svg";
import Email from "../../../assets/img/getintouch/email.svg";

const QoutingForms = ({ closePopup }) => {
  const [forms, setForms] = useState(1);
  const [enable, setEnable] = useState(true);

  const primaryContacts = [
    {
      profile: Mobile,
      platform: "0435 463 085",
      link: "",
    },

    {
      profile: Office,
      platform: "03 1234 5678",
      link: "",
    },
    {
      profile: Website,
      platform: "www.tradesposter.com.au",
      link: "",
    },
    {
      profile: Email,
      platform: "info@tradesposter.com.au",
      link: "info@tradesposter.com.au",
    },
  ];
  return (
    <>
      {forms === 1 && (
        <>
          <div className="w-[300px] sm:w-[350px] md:w-[380px] lg:w-[400px] xl:w-[400px] 2xl:w-[400px] mx-auto mt-20 xl:mt-36">
            <div className="flex gap-x-3 mt-10">
              <img
                className="object-center rounded-full w-12 h-12 cursor-pointer shrink-0"
                src={icon}
                alt={"no-profile"}
              />
              <Heading variant={"h6"} className={"text-offblack "}>
                Initiate a service request to start your project quickly!
              </Heading>
            </div>
            <div className={"mt-10"}>
              <TextFiled
                variant={"general"}
                label={"What type of service do you need?"}
                placeholder={"e.g., plumbing, electrical work"}
              />
            </div>
            <div className="mt-10">
              <AuxiliaryText variant={"FieldLabel"}>
                How soon do you need this service?
              </AuxiliaryText>

              <div className="flex items-center justify-center gap-x-5 mt-2">
                <BubbleButton disabled={enable}>ASAP</BubbleButton>
                <BubbleButton disabled={enable}>Within a month</BubbleButton>
                <BubbleButton disabled={enable}>Not urgent</BubbleButton>
              </div>
            </div>
            <div className="mt-10">
              <TextArea
                variant={"general"}
                label={"Provide details about the job"}
                placeholder={
                  "Describe the work needed, any specific requirements, etc."
                }
              />
            </div>
            <div className="flex items-center justify-between mt-10">
              <span onClick={() => setForms(2)}>
                <InteractiveText
                  variant={"ActionLink"}
                  className={
                    "text-offblack hover:text-primaryblue cursor-pointer text-center"
                  }
                >
                  Contact options
                </InteractiveText>
              </span>
              <MainButton
                variant={"extrasmall"}
                onClick={() => {
                  setForms(3);
                  setTimeout(closePopup, 3000);
                }}
              >
                Submit
              </MainButton>
            </div>
          </div>
        </>
      )}
      {forms === 2 && (
        <>
          <div className="w-[300px] sm:w-[350px] md:w-[380px] lg:w-[400px] xl:w-[400px] 2xl:w-[400px] h-[570px] mx-auto">
            <div className="flex items-center gap-x-3 mt-10">
              <div className="w-[48px] h-[48px]">
                <img
                  className="object-center rounded-full w-[48px] h-[48px] cursor-pointer"
                  src={icon}
                  alt={"no-profile"}
                />
              </div>
              <div>
                <Heading variant={"h6"} className={"text-offblack"}>
                  We're just a click away
                </Heading>
              </div>
            </div>
            <div className="flex flex-col justify-center items-center gap-6 mt-20">
              {primaryContacts.map((e) => {
                return (
                  <>
                    <div className="flex items-center justify-center gap-x-6 w-[250px] mx-auto">
                      <div className="shrink-0">
                        <img
                          src={e.profile}
                          alt={e.platform}
                          className="w-[50px] h-[50px]"
                        />
                      </div>
                      <div className="w-full">
                        <Paragraph
                          variant={"MainParagraph"}
                          className={"text-offblack"}
                        >
                          {e.platform}
                        </Paragraph>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
            <div className="flex items-center justify-center gap-6 sticky top-[100vh]">
              <div>
                <i className="fi fi-brands-facebook text-offblack text-base cursor-pointer"></i>
              </div>
              <div>
                <i className="fi fi-brands-instagram text-offblack text-base cursor-pointer"></i>
              </div>
              {/* <i className="fi fi-brands-twitter text-offblack text-base cursor-pointer"></i> */}
              <div className="size-4 cursor-pointer mb-[3px]">
                <img
                  src={
                    require("../../../assets/img/social/twitter-alt.svg")
                      .default
                  }
                  alt="twitter"
                />
              </div>
              <div>
                <i className="fi fi-brands-linkedin text-offblack text-base cursor-pointer"></i>
              </div>
            </div>
          </div>
        </>
      )}

      {forms === 3 && (
        <div className="w-[300px] sm:w-[350px] md:w-[380px] lg:w-[380px] xl:w-[420px] 2xl:w-[500px] mx-auto mt-20 xl:mt-40">
          <Heading
            variant={"h6"}
            className={"text-center mb-10 text-offblack"}
          >
            Thank you!
          </Heading>
          <div className="flex">
            <div className="w-[100%]">
              <div className="max-w-[400px] mx-auto">
                <Paragraph variant={"MainParagraph"} className={"text-start"}>
                  Thank you for submitting your request! One of our team members
                  will be in touch at the earliest convenience. If your inquiry
                  is urgent, please call us directly.
                </Paragraph>
              </div>
              <div className="flex justify-center my-10">
                <i className="fi fi-ss-badge-check text-[50px] text-primaryblue"></i>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default QoutingForms;
