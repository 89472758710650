import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Heading from "../../../../components/common/Typography/Heading";
import Paragraph from "../../../../components/common/Typography/Paragraph";
import Popup from "../../../../components/popup/Popup";
import MainButton from "../../../../components/common/MainButton/MainButton";
import SpecialText from "../../../../components/common/Typography/SpecialText";

const Agreement = ({ clickHandler }) => {
  const [pop, setPop] = useState(true);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  const navigate = useNavigate();
  useEffect(() => {
    if (pop) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [pop]);
  const popupList = [
    {
      heading:
        "You affirm that all certifications and licenses you add are current and valid.",
    },
    {
      heading:
        "You consent to provide proof of such certifications upon a client's request.",
    },
    {
      heading:
        "Trades Poster is not liable for any misconduct arising from uncertified services.",
    },
    {
      heading:
        "Your agreement to these terms ensures trust and integrity in the services provided to all clients.",
    },
  ];
  return (
    <>
      <Popup
        setTrigger={() => {
          setPop(false);
          navigate(-1);
        }}
        ShowCrossButton={ShowCrossButton}
        // popupWidth={
        //   "w-[375px] h-auto sm:w-[500px] 2xl:w-[600px] 2xl:h-[500px] 3xl:h-[750px]"
        // }
        // crossPosition={
        //   "flex justify-end w-[16px] absolute left-[340px] sm:left-[465px] 2xl:left-[570px] top-3"
        // }
      >
        <div className="mx-[5%] md:mx-auto md:max-w-[500px]">
          <Heading variant={"h6"} className={"text-center mb-10 mt-20 xl:mt-28"}>
            Certification consent agreement
          </Heading>
          <div className="grid grid-cols-12 max-w-[500px] mx-auto">
            <div className="col-span-12">
              <Paragraph variant={"MainParagraph"} className={"text-offblack"}>
                Trades Poster is currently enhancing its digital certification
                verification system to provide better service and verification
                for all licensed trades. During this period, businesses can
                still add certifications to their profiles manually.
              </Paragraph>
              <Paragraph variant={"MainParagraph"} className={"mt-3"}>
                As a business opting to manually add certifications, you agree
                to the following terms:
              </Paragraph>
              <ul className={"mt-5"}>
                {popupList.map((item, index) => {
                  return (
                    <li key={index} className="mb-2 flex">
                      <i className="fi fi-rs-check text-sm text-primaryblue mr-5"></i>
                      <Paragraph
                        variant={"MainParagraph"}
                        className={"text-offblack -mt-[2px]"}
                      >
                        {item.heading}
                      </Paragraph>
                    </li>
                  );
                })}
              </ul>
              <div className="w-full mt-10">
                <div className="flex items-center">
                  <label
                    className="relative flex items-center rounded-full cursor-pointer mr-3"
                    htmlFor="link"
                  >
                    <input
                      type="checkbox"
                      className="before:content[''] peer relative h-4 w-4 cursor-pointer appearance-none rounded-[4px] border border-mediumgray transition-all before:absolute before:top-2/4 before:left-2/4 before:block before:h-12 before:w-12 before:-translate-y-2/4 before:-translate-x-2/4 before:rounded-full before:bg-blue-gray-500 before:opacity-0 before:transition-opacity checked:border-primaryblue checked:bg-primaryblue checked:before:bg-primaryblue"
                      id="link"
                    />
                    <span className="absolute text-white transition-opacity opacity-0 pointer-events-none top-[40%] left-2/4 -translate-y-2/4 -translate-x-2/4 peer-checked:opacity-100">
                      <i className="fi fi-rs-check text-[10px]"></i>
                    </span>
                  </label>
                  <SpecialText variant={"FootNoteDisclaimer"}>
                    Agreement to Terms & Conditions
                  </SpecialText>
                </div>
                <SpecialText
                  variant={"FootNoteDisclaimer"}
                  className={"w-full pl-7 mt-2"}
                >
                  I agree to the Terms and Conditions and confirm that I am
                  legally certified in the trades I am adding. I understand it
                  is my responsibility to provide proof of such certifications
                  upon request."
                </SpecialText>
              </div>

              <div className="flex justify-end">
                <MainButton
                  className={"mt-10"}
                  variant={"extrasmall"}
                  onClick={clickHandler}
                >
                  Agree
                </MainButton>
              </div>
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};

export default Agreement;
