import React, { useState } from "react";
// import useAuth from "../../../../Hooks/useAuth";
import MainButton from "../../../../components/common/MainButton/MainButton";
import Paragraph from "../../../../components/common/Typography/Paragraph";

const SelectPostType = ({ setStep, setFieldValue, values }) => {
  // const { auth } = useAuth();
  // const isFreeUser = auth?.subscription?.name === "FREE";

  return (
    <>
      <form>
        <div className="flex flex-col lg:flex-row items-center justify-center gap-8 w-full xl:w-[832px] 2xl:w-[832px] mx-auto mt-[60px]">
          {/* Regular */}
          <div className="w-full lg:w-[300px] xl:w-[400px] h-auto p-5 bg-white lg:bg-pastalblue text-offblack rounded-xl">
            <div className="mt-5">
              <div className="flex justify-center">
                <i className="fi fi-tr-blog-text text-[30px]"></i>
              </div>
              <Paragraph
                variant={"MainParagraph"}
                className={"text-center mt-5"}
              >
                Basic visibility on the service page.
              </Paragraph>
            </div>
            <div className="flex justify-center mt-5 xl:mt-10">
              <MainButton
                variant={
                  values.selectedType === "Regular" ? "medium" : "outlinemedium"
                }
                onClick={() => {
                  setFieldValue("selectedType", "Regular");
                  setStep(2);
                }}
              >
                Regular
              </MainButton>
            </div>
          </div>
          {/* Featured */}
          <div className="w-full lg:w-[300px] xl:w-[400px] h-auto p-5 bg-white lg:bg-pastalblue text-offblack rounded-xl">
            <div className="mt-5">
              <div className="flex justify-center">
                <i className="fi fi-tr-blog-text text-[30px]"></i>
              </div>
              <Paragraph
                variant={"MainParagraph"}
                className={"text-center mt-5"}
              >
                Pro placement for higher visibility.
              </Paragraph>
            </div>
            <div className="flex justify-center mt-5 xl:mt-10">
              <MainButton
                variant={
                  values.selectedType === "Feature" ? "medium" : "outlinemedium"
                }
                onClick={() => {
                  setFieldValue("selectedType", "Feature");
                  setStep(2)
                }}
              >
                Feature
              </MainButton>
              {/* <MainButton
                variant={"outlinemedium"}
                onClick={() => {
                  if (!isFreeUser) {
                    setIsFeature(true);
                    setIsRegular(false);
                  }
                  nextStep();
                }}
              >
                Featured
              </MainButton> */}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default SelectPostType;
