import React, { useState } from "react";
import MainButton from "../../../components/common/MainButton/MainButton";
import VerificationPopUp from "../../VerificationPop/VerificationPopUp";
import TextFiled from "../../../components/common/InputField/TextFiled";
import Popup from "../../../components/popup/Popup";

const Email = () => {
  const [verify, setVerify] = useState(false);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  return (
    <>
      <form>
        <div className="grid grid-cols-12 w-fit mx-auto">
          <div className="col-span-12">
            <div>
              <TextFiled
                className={"lg:bg-pastalblue"}
                variant={"large"}
                label={"Email address"}
                placeholder={"Email address"}
              />
            </div>
            <div className="mt-5">
              <TextFiled
                className={"lg:bg-pastalblue"}
                variant={"large"}
                label={"New email address"}
                placeholder={"Enter new email address"}
              />
            </div>
            <div className="flex items-center justify-end mt-10">
              <MainButton
                variant={"small"}
                type="button"
                onClick={() => setVerify(true)}
              >
                Verify
              </MainButton>
            </div>
            {verify && (
              <>
                {verify && (
                  <Popup
                    setTrigger={() => setVerify(false)}
                    ShowCrossButton={ShowCrossButton}
                    popupWidth={"w-[350px] h-[450px] sm:w-[600px] sm:h-[500px] 2xl:w-[550px] 2xl:h-[600px]"}
              crossPosition={
                "flex justify-end w-[16px] absolute left-[310px] sm:left-[555px] 2xl:left-[505px] top-3"
              }
                  >
                    <VerificationPopUp
                      closePopup={() => setVerify(false)}
                      firstMainHeading={"Email verification"}
                      label={"Varification code"}
                      placeholder={"Input the code here."}
                      firstP={
                        "Check your inbox for a verification code and enter it below. Confirming your email helps safeguard your account and ensures reliable communication."
                      }
                      secondMainHeading={"Email verified"}
                      secondP={
                        "Success! Your email is verified, and you’ll now receive important updates. Thank you for keeping your account secure."
                      }
                      buttonText={"Submit"}
                    />
                  </Popup>
                )}
              </>
            )}
          </div>
        </div>
      </form>
    </>
  );
};

export default Email;
