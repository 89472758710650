import React from "react";
import TextFiled from "../InputField/TextFiled";
import Heading from "../Typography/Heading";
import MainButton from "../MainButton/MainButton";
import TextArea from "../InputField/TextArea";

const QuestionAdditionForm = ({ closePopup }) => {
  return (
    <>
      <div className="w-[300px] sm:w-[350px] md:w-[380px] lg:w-[400px] xl:w-[400px] 2xl:w-[500px] mx-auto mt-20 xl:mt-40">
        <Heading
          variant={"h6"}
          className={"text-center mb-10 text-offblack"}
        >
          Add your question
        </Heading>
        <form>
          <div className="mx-auto rounded-lg w-[300px] sm:w-[350px] md:w-[380px] lg:w-[400px] xl:w-[400px] 2xl:w-[400px] overflow-y-auto hidden-scrollbar pb-5">
            <div>
              <TextFiled
                variant={"general"}
                label={"Question"}
                placeholder={"please write your question"}
              />
            </div>
            <div className="mt-5">
              <TextArea
                variant={"general"}
                label={"Answer"}
                placeholder={"Write relevent answer"}
              />
            </div>
            <div className="flex justify-end mt-10">
              <MainButton type="button" variant={"extrasmall"} onClick={closePopup}>
                Add
              </MainButton>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default QuestionAdditionForm;
