import React from "react";
import Heading from "../../components/common/Typography/Heading";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";
import { useParams } from "react-router-dom";

const Factors = () => {
  const { slug } = useParams();
  const data = [
    {
      slug: "architectural-services",
      listHeading: "Factors that impact architectural costs",
      list: [
        {
          inlineHeading: "Size",
          inlineParagraph: "Larger projects cost more.",
        },
        {
          inlineHeading: "Complexity",
          inlineParagraph: "Unique designs increase expenses.",
        },
        {
          inlineHeading: "Materials",
          inlineParagraph: "Quality affects pricing.",
        },
        {
          inlineHeading: "Location",
          inlineParagraph: "Varies by local regulations.",
        },
        {
          inlineHeading: "Sustainability",
          inlineParagraph: "Eco-friendly planning may add costs.",
        },
      ],
    },
    {
      slug: "air-conditioning-and-heating",
      listHeading: "Factors that impact HVAC costs",
      list: [
        {
          inlineHeading: "System type",
          inlineParagraph: "Ducted systems are pricier than split units.",
        },
        {
          inlineHeading: "Energy rating",
          inlineParagraph: "Higher ratings cost more upfront but save energy.",
        },
        {
          inlineHeading: "Labor rates",
          inlineParagraph: "Depend on location and service.",
        },
        {
          inlineHeading: "Urgency",
          inlineParagraph: "Emergency work raises costs.",
        },
        {
          inlineHeading: "Accessibility",
          inlineParagraph: "Difficult access increases labour fees.",
        },
      ],
    },
    {
      slug: "bricklaying-and-blockwork",
      listHeading: "Factors that impact bricklaying costs",
      list: [
        {
          inlineHeading: "Wall size",
          inlineParagraph: "Larger walls require more materials and labour.",
        },
        {
          inlineHeading: "Material quality",
          inlineParagraph: "Higher-quality bricks or mortar increase costs.",
        },
        {
          inlineHeading: "Labor rates",
          inlineParagraph: "Vary based on location and project complexity.",
        },
        {
          inlineHeading: "Design complexity",
          inlineParagraph: "Decorative patterns or curved walls add to costs.",
        },
        {
          inlineHeading: "Accessibility",
          inlineParagraph: "Hard-to-reach areas can increase labour costs.",
        },
      ],
    },
    {
      slug: "cabinet-making",
      listHeading: "Factors that impact cabinet making  costs",
      list: [
        {
          inlineHeading: "Size & complexity",
          inlineParagraph: "Larger or intricate units require more resources.",
        },
        {
          inlineHeading: "Material quality",
          inlineParagraph: "Premium options, like solid timber, raise costs.",
        },
        {
          inlineHeading: "Customization",
          inlineParagraph: "Unique designs increase expenses.",
        },
        {
          inlineHeading: "Labor rates",
          inlineParagraph: "Vary by location and skill level.",
        },
        {
          inlineHeading: "Finishing options",
          inlineParagraph: "Specialty finishes add to overall costs.",
        },
      ],
    },
    {
      slug: "carpentry",
      listHeading: "Factors that impact carpentry costs",
      list: [
        {
          inlineHeading: "Material quality",
          inlineParagraph: "Timber type and treatment affect cost.",
        },
        {
          inlineHeading: "Complexity",
          inlineParagraph: "Detailed designs need more labour.",
        },
        {
          inlineHeading: "Size & scope",
          inlineParagraph: "Larger projects cost more.",
        },
        {
          inlineHeading: "Finishing",
          inlineParagraph: "Staining or painting raises costs.",
        },
        {
          inlineHeading: "Location",
          inlineParagraph: "Hard-to-reach sites add labour.",
        },
      ],
    },
    {
      slug: "carpet-and-flooring",
      listHeading: "Factors that impact flooring costs",
      list: [
        {
          inlineHeading: "Material type",
          inlineParagraph: "Premium options like hardwood or stone cost more.",
        },
        {
          inlineHeading: "Project size",
          inlineParagraph: "Larger areas need more materials and labour.",
        },
        {
          inlineHeading: "Complexity",
          inlineParagraph: "Intricate designs take more time and skill.",
        },
        {
          inlineHeading: "Underlay",
          inlineParagraph: "High-quality options add to costs.",
        },
        {
          inlineHeading: "Preparation",
          inlineParagraph: "Subfloor leveling or repairs increase expenses.",
        },
      ],
    },
    {
      slug: "caulking-services",
      listHeading: "Factors that impact caulking costs",
      list: [
        {
          inlineHeading: "Material",
          inlineParagraph:
            "Silicone, polyurethane, and specialty caulks vary in cost.",
        },
        {
          inlineHeading: "Project size",
          inlineParagraph: "More gaps need more materials and labor.",
        },
        {
          inlineHeading: "Location",
          inlineParagraph: "Exterior or high spots need special equipment.",
        },
        {
          inlineHeading: "Condition",
          inlineParagraph: "Old caulk needs extra prep and labor.",
        },
        {
          inlineHeading: "Accessibility",
          inlineParagraph: "Hard-to-reach spots increase labor costs.",
        },
      ],
    },
    {
      slug: "cleaning-services",
      listHeading: "Factors that impact cleaning costs",
      list: [
        {
          inlineHeading: "Service type",
          inlineParagraph: "Specialized cleaning costs more.",
        },
        {
          inlineHeading: "Property size",
          inlineParagraph: "Larger areas need more time/resources.",
        },
        {
          inlineHeading: "Dirtiness",
          inlineParagraph: "Heavily soiled areas need extra effort.",
        },
        {
          inlineHeading: "Frequency",
          inlineParagraph: "Regular packages cost less than one-time.",
        },
        {
          inlineHeading: "Accessibility",
          inlineParagraph: "Hard-to-reach areas increase labor.",
        },
      ],
    },
    {
      slug: "concrete-and-paving",
      listHeading: "Factors that impact concrete & paving costs",
      list: [
        {
          inlineHeading: "Material choice",
          inlineParagraph: "Exposed aggregate or stone costs more.",
        },
        {
          inlineHeading: "Surface size",
          inlineParagraph: "Larger areas need more materials and labor.",
        },
        {
          inlineHeading: "Design complexity",
          inlineParagraph: "Stamped or stenciled finishes cost extra.",
        },
        {
          inlineHeading: "Site prep",
          inlineParagraph:
            "Subbase work, excavation, or leveling raises costs.",
        },
        {
          inlineHeading: "Weather protection",
          inlineParagraph: "Sealing and treatments add to expenses.",
        },
      ],
    },
    {
      slug: "conveyancing",
      listHeading: "Factors that impact conveyancing costs",
      list: [
        {
          inlineHeading: "Property Type",
          inlineParagraph: "Commercial involves more legal work.",
        },
        {
          inlineHeading: "Complexity",
          inlineParagraph: "Subdivisions or multi-party deals cost more.",
        },
        {
          inlineHeading: "Location",
          inlineParagraph: "State and local rules affect fees.",
        },
        {
          inlineHeading: "Documentation",
          inlineParagraph: "More paperwork raises costs.",
        },
        {
          inlineHeading: "Urgency",
          inlineParagraph: "Fast settlements increase fees.",
        },
      ],
    },
    {
      slug: "curtains-blinds-and-awnings",
      listHeading: "Factors that impact window covering costs",
      list: [
        {
          inlineHeading: "Material choice",
          inlineParagraph: "Timber or motorized components are more expensive.",
        },
        {
          inlineHeading: "Size & scope",
          inlineParagraph:
            "Larger windows or multiple installations increase costs.",
        },
        {
          inlineHeading: "Automation",
          inlineParagraph: "Motorized blinds add to overall expenses.",
        },
        {
          inlineHeading: "Outdoor conditions",
          inlineParagraph:
            "Weather-resistant materials are needed for external fittings.",
        },
        {
          inlineHeading: "Customization",
          inlineParagraph: "Custom designs cost more than standard options.",
        },
      ],
    },
    {
      slug: "decking-gazebo-and-carports",
      listHeading: "Factors that impact decking & outdoor structures costs",
      list: [
        {
          inlineHeading: "Material choice",
          inlineParagraph: "Hardwood or composite materials cost more.",
        },
        {
          inlineHeading: "Design complexity",
          inlineParagraph:
            "Custom features like balustrades or roofs increase expenses.",
        },
        {
          inlineHeading: "Site preparation",
          inlineParagraph:
            "Leveling, foundation work, or removing old structures adds costs.",
        },
        {
          inlineHeading: "Weatherproofing",
          inlineParagraph:
            "Sealing, waterproofing, and shading systems raise costs.",
        },
        {
          inlineHeading: "Size & scope",
          inlineParagraph: "Larger projects need more materials and labor.",
        },
      ],
    },
    {
      slug: "demolition-and-excavation",
      listHeading: "Factors that impact demolition & excavation costs",
      list: [
        {
          inlineHeading: "Project size",
          inlineParagraph: "Bigger sites need more time, labor, and equipment.",
        },
        {
          inlineHeading: "Material type",
          inlineParagraph:
            "Handling hazardous materials like asbestos raises costs.",
        },
        {
          inlineHeading: "Site access",
          inlineParagraph:
            "Difficult or confined access requires specialized equipment.",
        },
        {
          inlineHeading: "Waste disposal",
          inlineParagraph: "Proper disposal and recycling add expenses.",
        },
        {
          inlineHeading: "Preparation work",
          inlineParagraph:
            "Soil testing, surveys, and clearing increase costs.",
        },
      ],
    },
    {
      slug: "electrical-services",
      listHeading: "Factors that impact electrical costs",
      list: [
        {
          inlineHeading: "Complexity",
          inlineParagraph:
            "Advanced installations like solar systems or rewiring cost more.",
        },
        {
          inlineHeading: "Property size",
          inlineParagraph:
            "Bigger properties need more materials, labour, and time.",
        },
        {
          inlineHeading: "Urgency",
          inlineParagraph:
            "Emergency repairs come with higher fees for immediate service.",
        },
        {
          inlineHeading: "Equipment type",
          inlineParagraph:
            "Costs vary by equipment quality, brand, and efficiency.",
        },
        {
          inlineHeading: "Accessibility",
          inlineParagraph: "Hard-to-reach areas increase labour expenses.",
        },
      ],
    },
    {
      slug: "engineering-services",
      listHeading: "Factors that impact engineering costs",
      list: [
        {
          inlineHeading: "Project complexity",
          inlineParagraph:
            "Detailed analysis and design for complex projects increase costs.",
        },
        {
          inlineHeading: "Scope of work",
          inlineParagraph:
            "Broader scopes, like multi-disciplinary projects, raise expenses.",
        },
        {
          inlineHeading: "Materials",
          inlineParagraph:
            "High-grade materials often need specialized design, adding to costs.",
        },
        {
          inlineHeading: "Regulations",
          inlineParagraph: "Compliance with stringent codes can be costly.",
        },
        {
          inlineHeading: "Site conditions",
          inlineParagraph:
            "Difficult sites may require extra geotechnical or civil work.",
        },
      ],
    },
    {
      slug: "fencing-and-gates",
      listHeading: "Factors that impact fencing & gate costs",
      list: [
        {
          inlineHeading: "Material type",
          inlineParagraph:
            "Premium options like wrought iron or Colorbond are pricier.",
        },
        {
          inlineHeading: "Project size",
          inlineParagraph:
            "Larger installations need more materials and labour.",
        },
        {
          inlineHeading: "Automation",
          inlineParagraph: "Electric gates and security systems add expenses.",
        },
        {
          inlineHeading: "Ground conditions",
          inlineParagraph:
            "Sloped or uneven terrain increases preparation costs.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Safety measures for pool fencing add to overall costs.",
        },
      ],
    },
    {
      slug: "glass-and-glazing",
      listHeading: "Factors that impact glazing & screen costs",
      list: [
        {
          inlineHeading: "Glass type",
          inlineParagraph: "Toughened or double-glazed glass costs more.",
        },
        {
          inlineHeading: "Size & customization",
          inlineParagraph: "Larger or custom designs increase costs.",
        },
        {
          inlineHeading: "Safety",
          inlineParagraph: "Compliance and safety glass add expenses.",
        },
        {
          inlineHeading: "Waterproofing",
          inlineParagraph: "Needed for wet areas, raising costs.",
        },
        {
          inlineHeading: "Complexity",
          inlineParagraph: "Curved or frameless designs are pricier.",
        },
      ],
    },
    {
      slug: "garden-and-landscaping",
      listHeading: "Factors that impact landscaping costs",
      list: [
        {
          inlineHeading: "Project scope",
          inlineParagraph: "Larger projects with extensive features cost more.",
        },
        {
          inlineHeading: "Material choice",
          inlineParagraph:
            "Using premium materials like natural stone or exotic plants raises costs.",
        },
        {
          inlineHeading: "Design complexity",
          inlineParagraph:
            "Intricate, multi-level designs require more labour and expertise.",
        },
        {
          inlineHeading: "Soil & climate",
          inlineParagraph:
            "Poor soil or harsh climates may need special plants or materials.",
        },
        {
          inlineHeading: "Ongoing maintenance",
          inlineParagraph:
            "Initial investment in sustainable designs can lower future upkeep costs.",
        },
      ],
    },
    {
      slug: "handyman-services",
      listHeading: "Factors that impact handyman costs",
      list: [
        {
          inlineHeading: "Job complexity",
          inlineParagraph:
            "Plumbing or electrical repairs cost more than simpler tasks.",
        },
        {
          inlineHeading: "Materials needed",
          inlineParagraph:
            "Costs increase if supplies like paint, tiles, or wood are required.",
        },
        {
          inlineHeading: "Urgency",
          inlineParagraph: "Emergency repairs often come with higher rates.",
        },
        {
          inlineHeading: "Duration",
          inlineParagraph:
            "Longer tasks or multiple jobs can raise labour costs.",
        },
        {
          inlineHeading: "Location",
          inlineParagraph:
            "Travel fees may apply if you’re outside the service area.",
        },
      ],
    },
    {
      slug: "inspections-and-surveyors",
      listHeading: "Factors that impact inspection & surveying costs",
      list: [
        {
          inlineHeading: "Property size",
          inlineParagraph: "Larger areas require more time and detail.",
        },
        {
          inlineHeading: "Complexity",
          inlineParagraph:
            "Specialized surveys or detailed inspections cost more.",
        },
        {
          inlineHeading: "Urgency",
          inlineParagraph: "Rush requests add to fees.",
        },
        {
          inlineHeading: "Documentation",
          inlineParagraph: "Detailed reports increase costs.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph: "Extra checks for legal standards raise expenses.",
        },
      ],
    },
    {
      slug: "security-and-automation",
      listHeading: "Factors that impact security & automation costs",
      list: [
        {
          inlineHeading: "System type",
          inlineParagraph: "Advanced, feature-rich systems are pricier.",
        },
        {
          inlineHeading: "Property size",
          inlineParagraph:
            "Larger properties need more devices and setup time.",
        },
        {
          inlineHeading: "Integration level",
          inlineParagraph:
            "Comprehensive setups with multiple devices cost more.",
        },
        {
          inlineHeading: "Remote monitoring",
          inlineParagraph: "Ongoing fees for professional monitoring services.",
        },
        {
          inlineHeading: "Installation complexity",
          inlineParagraph:
            "Difficult installations or extensive wiring increase labour costs.",
        },
      ],
    },
    {
      slug: "insulation-services",
      listHeading: "Factors that impact insulation costs",
      list: [
        {
          inlineHeading: "Material",
          inlineParagraph: "High-performance types cost more.",
        },
        {
          inlineHeading: "Size",
          inlineParagraph: "Larger properties need more resources.",
        },
        {
          inlineHeading: "Complexity",
          inlineParagraph: "Hard-to-reach areas increase labour.",
        },
        {
          inlineHeading: "Removal",
          inlineParagraph: "Old or hazardous insulation adds cost.",
        },
      ],
    },
    {
      slug: "interior-design",
      listHeading: "Factors that impact interior design costs",
      list: [
        {
          inlineHeading: "Project scope",
          inlineParagraph: "Larger or multi-room projects cost more.",
        },
        {
          inlineHeading: "Material quality",
          inlineParagraph: "Premium materials and fixtures raise costs.",
        },
        {
          inlineHeading: "Customization",
          inlineParagraph: "Bespoke designs or furniture add expense.",
        },
        {
          inlineHeading: "Design complexity",
          inlineParagraph: "Intricate layouts increase costs.",
        },
        {
          inlineHeading: "Sustainability",
          inlineParagraph: "Eco-friendly choices may raise upfront costs.",
        },
      ],
    },
    {
      slug: "locksmiths",
      listHeading: "Factors that impact locksmith costs",
      list: [
        {
          inlineHeading: "Lock type",
          inlineParagraph: "High-security or digital locks cost more.",
        },
        {
          inlineHeading: "Urgency",
          inlineParagraph: "Emergency or after-hours services add fees.",
        },
        {
          inlineHeading: "Property size",
          inlineParagraph:
            "Large properties with multiple locks increase costs.",
        },
        {
          inlineHeading: "Complexity",
          inlineParagraph:
            "Advanced systems, like digital locks, require more expertise.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph:
            "Meeting specific security standards can add to costs.",
        },
      ],
    },
    {
      slug: "painting-and-decorating",
      listHeading: "Factors that impact painting & decoration costs",
      list: [
        {
          inlineHeading: "Size",
          inlineParagraph: "Larger projects require more resources.",
        },
        {
          inlineHeading: "Quality",
          inlineParagraph: "Premium paints increase cost.",
        },
        {
          inlineHeading: "Prep",
          inlineParagraph: "Repairs and priming add expenses.",
        },
        {
          inlineHeading: "Complexity",
          inlineParagraph: "Custom designs need extra time.",
        },
        {
          inlineHeading: "Safety",
          inlineParagraph: "High-risk work requires precautions.",
        },
      ],
    },
    {
      slug: "moving-and-delivery",
      listHeading: "Factors that impact moving costs",
      list: [
        {
          inlineHeading: "Distance",
          inlineParagraph: "Longer moves cost more.",
        },
        {
          inlineHeading: "Volume",
          inlineParagraph: "More items need larger crews.",
        },
        {
          inlineHeading: "Packing",
          inlineParagraph: "Full packing adds cost.",
        },
        {
          inlineHeading: "Special items",
          inlineParagraph: "Delicate items increase fees.",
        },
        {
          inlineHeading: "Storage",
          inlineParagraph: "Cost depends on size and duration.",
        },
      ],
    },
    {
      slug: "mould-and-asbestos",
      listHeading: "Factors that impact mould & asbestos removal costs",
      list: [
        {
          inlineHeading: "Contamination",
          inlineParagraph: "Larger or severe cases require more resources.",
        },
        {
          inlineHeading: "Asbestos type",
          inlineParagraph: "Friable asbestos removal is more costly.",
        },
        {
          inlineHeading: "Building age",
          inlineParagraph: "Older buildings may need extensive removal.",
        },
        {
          inlineHeading: "Access",
          inlineParagraph: "Hard-to-reach areas increase costs.",
        },
        {
          inlineHeading: "Extras",
          inlineParagraph:
            "Air monitoring and preventive treatments add costs.",
        },
      ],
    },
    {
      slug: "pest-control",
      listHeading: "Factors that impact pest control costs",
      list: [
        {
          inlineHeading: "Infestation size",
          inlineParagraph: "Larger issues need more treatment.",
        },
        {
          inlineHeading: "Property size",
          inlineParagraph: "Bigger areas require more resources.",
        },
        {
          inlineHeading: "Treatment type",
          inlineParagraph: "Specialized options cost more.",
        },
        {
          inlineHeading: "Follow-ups",
          inlineParagraph: "Additional visits add costs.",
        },
        {
          inlineHeading: "Safety needs",
          inlineParagraph: "Safe chemical handling may increase expenses.",
        },
      ],
    },
    {
      slug: "solid-plastering",
      listHeading: "Factors that impact plastering costs",
      list: [
        {
          inlineHeading: "Project size",
          inlineParagraph: "Larger areas mean higher costs.",
        },
        {
          inlineHeading: "Material quality",
          inlineParagraph: "Premium materials increase expenses.",
        },
        {
          inlineHeading: "Surface condition",
          inlineParagraph: "Damaged areas need more prep.",
        },
        {
          inlineHeading: "Design complexity",
          inlineParagraph: "Decorative work costs more.",
        },
        {
          inlineHeading: "Safety requirements",
          inlineParagraph: "High ceilings and fire-rated plaster add costs.",
        },
      ],
    },
    {
      slug: "plumbing",
      listHeading: "Factors that impact plumbing costs",
      list: [
        {
          inlineHeading: "System size",
          inlineParagraph: "Larger systems cost more.",
        },
        {
          inlineHeading: "Material quality",
          inlineParagraph: "Better materials increase costs.",
        },
        {
          inlineHeading: "Labor rates",
          inlineParagraph: "Vary by location and time.",
        },
        {
          inlineHeading: "Urgency",
          inlineParagraph: "Emergency services are pricier.",
        },
        {
          inlineHeading: "Accessibility",
          inlineParagraph: "Hard-to-reach areas add costs.",
        },
      ],
    },
    {
      slug: "pools-and-spas",
      listHeading: "Factors that impact pool & spa costs",
      list: [
        {
          inlineHeading: "Size & type",
          inlineParagraph: "Bigger or complex designs cost more.",
        },
        {
          inlineHeading: "Material",
          inlineParagraph: "Concrete costs more than fiberglass or vinyl.",
        },
        {
          inlineHeading: "Features",
          inlineParagraph: "Heating, lighting, and jets increase costs.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph: "Safety features add expenses.",
        },
        {
          inlineHeading: "Maintenance",
          inlineParagraph: "Varies by service frequency and chemicals.",
        },
      ],
    },
    {
      slug: "rendering",
      listHeading: "Factors that impact rendering costs",
      list: [
        {
          inlineHeading: "Project size",
          inlineParagraph: "Larger areas need more labour and materials.",
        },
        {
          inlineHeading: "Render type",
          inlineParagraph: "Insulated or specialty renders cost more.",
        },
        {
          inlineHeading: "Surface condition",
          inlineParagraph: "Damaged walls require extra prep.",
        },
        {
          inlineHeading: "Finish complexity",
          inlineParagraph: "Textured or polished finishes add labour costs.",
        },
        {
          inlineHeading: "Color additives",
          inlineParagraph: "Pigments increase material expenses.",
        },
      ],
    },
    {
      slug: "renovation-and-restoration",
      listHeading: "Factors that impact renovation & restoration costs",
      list: [
        {
          inlineHeading: "Scope",
          inlineParagraph: "Bigger projects require more labour and materials.",
        },
        {
          inlineHeading: "Materials",
          inlineParagraph: "Premium options increase expenses.",
        },
        {
          inlineHeading: "Heritage",
          inlineParagraph: "Specialized methods add to costs.",
        },
        {
          inlineHeading: "Structural",
          inlineParagraph: "Fixes raise overall costs.",
        },
        {
          inlineHeading: "Accessibility",
          inlineParagraph: "Compliance modifications add expenses.",
        },
      ],
    },
    {
      slug: "roofing",
      listHeading: "Factors that impact roofing costs",
      list: [
        {
          inlineHeading: "Size & type",
          inlineParagraph: "Bigger, complex roofs cost more.",
        },
        {
          inlineHeading: "Materials",
          inlineParagraph: "Premium options like metal increase expense.",
        },
        {
          inlineHeading: "Condition",
          inlineParagraph: "Repairs or structural fixes add costs.",
        },
        {
          inlineHeading: "Access",
          inlineParagraph: "Difficult access raises prices.",
        },
        {
          inlineHeading: "Weather",
          inlineParagraph: "Delays from rain or wind affect labour costs.",
        },
      ],
    },
    {
      slug: "scaffolding",
      listHeading: "Factors that impact scaffolding costs",
      list: [
        {
          inlineHeading: "Height",
          inlineParagraph: "Taller projects require more scaffolding.",
        },
        {
          inlineHeading: "Type",
          inlineParagraph: "Heavy-duty or suspended scaffolds cost more.",
        },
        {
          inlineHeading: "Duration",
          inlineParagraph: "Longer hire periods add to costs.",
        },
        {
          inlineHeading: "Safety",
          inlineParagraph: "Extra safety measures increase expenses.",
        },
        {
          inlineHeading: "Accessibility",
          inlineParagraph: "Complex sites may need specialized setups.",
        },
      ],
    },
    {
      slug: "shopfitting",
      listHeading: "Factors that impact shopfitting costs",
      list: [
        {
          inlineHeading: "Size",
          inlineParagraph: "Larger projects need more resources.",
        },
        {
          inlineHeading: "Materials",
          inlineParagraph: "Premium finishes add cost.",
        },
        {
          inlineHeading: "Complexity",
          inlineParagraph: "Custom designs increase expenses.",
        },
        {
          inlineHeading: "Compliance",
          inlineParagraph: "Safety standards impact budget.",
        },
        {
          inlineHeading: "Integration",
          inlineParagraph: "Systems like plumbing and security raise costs.",
        },
      ],
    },
    {
      slug: "skip-bins",
      listHeading: "Factors that impact skip bin costs",
      list: [
        {
          inlineHeading: "Bin size",
          inlineParagraph: "Larger bins cost more.",
        },
        {
          inlineHeading: "Waste type",
          inlineParagraph: "Heavier or hazardous waste increases fees.",
        },
        {
          inlineHeading: "Duration",
          inlineParagraph: "Longer hires add to costs.",
        },
        {
          inlineHeading: "Location",
          inlineParagraph: "Delivery and pickup vary by distance.",
        },
        {
          inlineHeading: "Permits",
          inlineParagraph: "Additional costs for bins on public property.",
        },
      ],
    },
    {
      slug: "solar-power",
      listHeading: "Factors that impact solar power costs",
      list: [
        {
          inlineHeading: "System size",
          inlineParagraph: "Bigger systems are pricier.",
        },
        {
          inlineHeading: "Battery storage",
          inlineParagraph: "Batteries add cost but ensure backup.",
        },
        {
          inlineHeading: "Roof type",
          inlineParagraph: "Complex roofs increase installation costs.",
        },
        {
          inlineHeading: "Inverter",
          inlineParagraph: "High-efficiency inverters raise prices.",
        },
        {
          inlineHeading: "Installation",
          inlineParagraph: "Complex setups like off-grid increase costs.",
        },
      ],
    },
    {
      slug: "staircase-services",
      listHeading: "Factors that impact staircase costs",
      list: [
        {
          inlineHeading: "Materials",
          inlineParagraph: "Wood, metal, and glass vary in price.",
        },
        {
          inlineHeading: "Design",
          inlineParagraph: "Custom or complex designs need more labour.",
        },
        {
          inlineHeading: "Height & structure",
          inlineParagraph: "Taller, multi-level stairs cost more.",
        },
        {
          inlineHeading: "Safety features",
          inlineParagraph: "Handrails and non-slip finishes add cost.",
        },
        {
          inlineHeading: "Accessibility",
          inlineParagraph: "Compliance features increase costs.",
        },
      ],
    },
    {
      slug: "stonework",
      listHeading: "Factors that impact stone costs",
      list: [
        {
          inlineHeading: "Stone type",
          inlineParagraph: "Natural stones cost more.",
        },
        {
          inlineHeading: "Design",
          inlineParagraph: "Custom designs increase costs.",
        },
        {
          inlineHeading: "Size",
          inlineParagraph: "Larger projects need more materials.",
        },
        {
          inlineHeading: "Heritage",
          inlineParagraph: "Restoration requires specialized work.",
        },
        {
          inlineHeading: "Accessibility",
          inlineParagraph: "Hard-to-reach areas add costs.",
        },
      ],
    },
    {
      slug: "tiling",
      listHeading: "Factors that impact tiling costs",
      list: [
        {
          inlineHeading: "Tile type",
          inlineParagraph: "Natural stone/custom tiles cost more.",
        },
        {
          inlineHeading: "Size & design",
          inlineParagraph: "Small or intricate tiles increase costs.",
        },
        {
          inlineHeading: "Prep work",
          inlineParagraph: "Additional surface prep adds cost.",
        },
        {
          inlineHeading: "Grout & sealants",
          inlineParagraph: "Premium options raise prices.",
        },
        {
          inlineHeading: "Waterproofing",
          inlineParagraph: "Wet areas need extra waterproofing.",
        },
      ],
    },
    {
      slug: "waterproofing",
      listHeading: "Factors that impact waterproofing costs",
      list: [
        {
          inlineHeading: "Area size",
          inlineParagraph: "Bigger areas cost more.",
        },
        {
          inlineHeading: "Materials",
          inlineParagraph: "Premium products increase costs.",
        },
        {
          inlineHeading: "Prep",
          inlineParagraph: "More prep adds to the price.",
        },
        {
          inlineHeading: "Exposure",
          inlineParagraph: "High-water areas need stronger solutions.",
        },
        {
          inlineHeading: "Access",
          inlineParagraph: "Hard-to-reach spots cost more.",
        },
      ],
    },
  ];
  const categoryData = data.filter((item) => item.slug === slug);
  return (
    <>
      {categoryData.map((item, index) => {
        return (
          <div className="mx-[5%] md:mx-auto md:w-[600px] mt-10">
            <div key={index}>
              <Heading variant={"h3"} className={"text-offblack"}>
                {item.listHeading}
              </Heading>
              {item.list && (
                <ul className="flex flex-col space-y-2 mt-5">
                  {item.list.map((item, index) => {
                    return (
                      <li className={"inline"} key={index}>
                        <SecondaryHeading
                          variant={"MainParagraphHeading"}
                          className={"inline font-semibold"}
                        >
                          {item.inlineHeading}
                        </SecondaryHeading>
                        <Paragraph
                          variant={"MainParagraph"}
                          className={"inline text-offblack"}
                        >
                          &nbsp;&nbsp;{item.inlineParagraph}
                        </Paragraph>
                      </li>
                    );
                  })}
                </ul>
              )}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default Factors;
