import React from "react";
import MainButton from "../MainButton/MainButton";
import TextArea from "../InputField/TextArea";
import { useState } from "react";
import RatingStars from "../RatingStars/RatingStars";
import TextFiled from "../InputField/TextFiled";
import Paragraph from "../Typography/Paragraph";
import Heading from "../Typography/Heading";
import { Link } from "react-router-dom";
import SecondaryHeading from "../Typography/SecondaryHeading";
import SpecialText from "../Typography/SpecialText";
import ReviewGuidelines from "./ReviewGuidelines";

const FormDesign = ({ closePopup }) => {
  const [step, setStep] = useState(1);
  const ReviewItems = [
    {
      text: "Communication",
    },
    {
      text: "Reliability",
    },
    {
      text: "Satisfaction",
    },
    {
      text: "Recommend",
    },
    {
      text: "Punctuality",
    },
  ];

  return (
    <>
      {step === 1 && (
        <>
          <div className="w-[300px] sm:w-[350px] md:w-[380px] lg:w-[400px] mx-auto">
            <Heading
              variant={"h6"}
              className={"flex items-center justify-center mt-20 xl:mt-40 text-offblack"}
            >
              Leave a review
            </Heading>
            <div className="h-[510px] overflow-auto hidden-scrollbar pt-10 pb-20">
              <form>
                <div className="flex justify-center mx-[5%] md:mx-auto">
                  <div className="w-[350px] md:w-full mx-auto">
                    <div>
                      <div>
                        <TextFiled
                          variant={"general"}
                          label={"First name"}
                          placeholder={"Enter first name"}
                        />
                      </div>
                      <div className="mt-5">
                        <TextFiled
                          variant={"general"}
                          label={"Last name"}
                          placeholder={"Enter last name"}
                        />
                      </div>
                    </div>
                    <div className=" mt-5">
                      <div>
                        <TextFiled
                          variant={"general"}
                          label={"Email"}
                          placeholder={"Enter email address"}
                        />
                      </div>
                      <div className="mt-5">
                        <TextFiled
                          variant={"general"}
                          label={"Mobile"}
                          placeholder={"Enter mobile number"}
                        />
                      </div>
                    </div>
                    <div className="bg-white text-offblack mt-10 rounded-2xl px-5 md:px-20 py-10 w-full">
                      {ReviewItems?.map((item) => {
                        return (
                          <>
                            <div className="flex items-center justify-between mt-1">
                              <Paragraph variant={"MainParagraph"}>
                                {item?.text}
                              </Paragraph>
                              <RatingStars rating={3} />
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="mt-5">
                      <TextArea
                        label={"Share your experience in words"}
                        variant={"general"}
                        placeholder="Share your experience with other Tradesposter customers"
                      />
                    </div>
                    <div>
                      <div className="mt-5">
                        <SpecialText variant={"FootNoteDisclaimer"}>
                          By selecting "Publish" you are agree to our&nbsp;
                          <span
                            // to={"/review-guidelines"}
                            // target="_blank"
                            className="text-primaryblue cursor-pointer"
                            onClick={() => {
                              setStep(3);
                              // setTimeout(closePopup, 20000);
                            }}
                          >
                            review guidelines
                          </span>
                          .
                        </SpecialText>
                      </div>
                      <div className="flex items-center justify-end mt-10">
                        <MainButton
                          variant={"extrasmall"}
                          onClick={() => {
                            setStep(2);
                            // setTimeout(closePopup, 20000);
                          }}
                        >
                          Publish
                        </MainButton>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
      )}
      {step === 2 && (
        <>
          <div className="w-[300px] sm:w-[350px] md:w-[380px] lg:w-[400px] xl:w-[400px] 2xl:w-[400px] mx-auto">
            <Heading
              variant={"h6"}
              className={"flex items-center justify-center mt-20 xl:mt-40 text-offblack"}
            >
              Thank you for your review!
            </Heading>
            <div className="h-[510px] overflow-auto hidden-scrollbar pt-10 pb-20">
              <div className="flex justify-center mx-[5%] md:mx-auto">
                <div className="w-[350px] md:w-full mx-auto">
                  <div>
                    <SecondaryHeading variant={"MainParagraphHeading"}>
                      Your Feedback is Valuable to Us and Our Community
                    </SecondaryHeading>
                  </div>
                  <div className="mt-5">
                    <Paragraph variant={"MainParagraph"}>
                      We've successfully received your review, and we can't
                      thank you enough for taking the time to share your
                      experience with the Trades Poster community. Your insights
                      not only help others make more informed decisions but also
                      empower businesses to improve and deliver better services.
                    </Paragraph>
                  </div>
                  <div className="mt-5">
                    <SecondaryHeading variant={"MainParagraphHeading"}>
                      What Happens Next?
                    </SecondaryHeading>
                  </div>
                  <div className="mt-5">
                    <SecondaryHeading variant={"MainParagraphHeading"}>
                      Review Moderation:
                    </SecondaryHeading>
                    <Paragraph variant={"MainParagraph"} className={"mt-1"}>
                      We've successfully received your review, and we can't
                      thank you enough for taking the time to share your
                      experience with the Trades Poster community. Your insights
                      not only help others make more informed decisions but also
                      empower businesses to improve and deliver better services.
                    </Paragraph>
                  </div>
                  <div className="mt-3">
                    <SecondaryHeading variant={"MainParagraphHeading"}>
                      Publication:
                    </SecondaryHeading>
                    <Paragraph variant={"MainParagraph"} className={"mt-1"}>
                      Once approved, your review will be published on the
                      business profile page. If there are any issues or if
                      additional information is needed, we may reach out to you
                      via the contact information you provided.
                    </Paragraph>
                  </div>
                  <div className="mt-3">
                    <SecondaryHeading variant={"MainParagraphHeading"}>
                      Stay Updated:
                    </SecondaryHeading>
                    <Paragraph variant={"MainParagraph"} className={"mt-1"}>
                      Keep an eye on the business profile to see your review go
                      live! You'll also be able to see responses from the
                      business and further engage with our community.
                    </Paragraph>
                  </div>
                  <div className="mt-5">
                    <SecondaryHeading variant={"MainParagraphHeading"}>
                      Share More, Help More
                    </SecondaryHeading>
                  </div>
                  <div className="mt-5">
                    <SecondaryHeading variant={"MainParagraphHeading"}>
                      Explore More Businesses
                    </SecondaryHeading>
                    <Paragraph variant={"MainParagraph"} className={"mt-1"}>
                      Your opinions matter. Consider reviewing other businesses
                      you've interacted with on Trades Poster. Each review helps
                      our community grow stronger.
                    </Paragraph>
                  </div>
                  <div className="mt-3">
                    <SecondaryHeading variant={"MainParagraphHeading"}>
                      Stay Engaged:
                    </SecondaryHeading>
                    <Paragraph variant={"MainParagraph"} className={"mt-1"}>
                      Keep an eye on your inbox for updates from Trades Poster,
                      including news, promotions, and more ways to engage with
                      our community.
                    </Paragraph>
                  </div>
                  <div className="mt-3">
                    <SecondaryHeading variant={"MainParagraphHeading"}>
                      Need Assistance?
                    </SecondaryHeading>
                    <Paragraph variant={"MainParagraph"} className={"mt-1"}>
                      If you have any questions or need further assistance,
                      don't hesitate to contact our support team. We're here to
                      help ensure your experience with Trades Poster is as
                      rewarding and positive as possible.
                    </Paragraph>
                  </div>
                  <div className="mt-3">
                    <SecondaryHeading variant={"MainParagraphHeading"}>
                      Thank You for Being a Valuable Part of the Trades Poster
                      Community!
                    </SecondaryHeading>
                    <Paragraph variant={"MainParagraph"} className={"mt-1"}>
                      Your feedback makes a difference. Thank you for
                      contributing to a trustworthy and helpful community.
                    </Paragraph>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {step === 3 && (
        <>
          <ReviewGuidelines handleBackClick={() => setStep(1)} />
        </>
      )}
    </>
  );
};

export default FormDesign;
