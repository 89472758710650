import React, { useEffect, useState } from "react";
import Popup from "../../../../components/popup/Popup";
import VerificationPopUp from "../../../../sections/VerificationPop/VerificationPopUp";
import MainButton from "../../../../components/common/MainButton/MainButton";
import Paragraph from "../../../../components/common/Typography/Paragraph";

const GeneraComponet = ({ heading, description, image, status }) => {
  const [link, setLink] = useState(false);
  const [ShowCrossButton, setShowCrossButton] = useState(true);
  useEffect(() => {
    if (link) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "visible";
      document.body.style.width = `auto`;
    }
  }, [link]);
  return (
    <>
      <div className="w-fit mx-auto">
        <div className="flex justify-center">
          <img
            className="w-[100px] h-[60px] md:w-[120px] md:h-[80px]"
            src={image}
            alt="Missing"
          />
        </div>
        <div className="flex justify-center my-3">
          <Paragraph variant={"MainParagraph"} className={"text-center"}>
            {heading}
          </Paragraph>
        </div>
        <div className="flex justify-center">
          <MainButton
            variant={status === "Remove" ? "outlineextrasmall" : "extrasmall"}
            onClick={() => setLink(true)}
          >
            {status}
          </MainButton>
        </div>
      </div>
      {link && (
        <Popup
          setTrigger={() => setLink(false)}
          ShowCrossButton={ShowCrossButton}
          // popupWidth={"w-[350px] h-[500px] sm:w-[600px] sm:h-[500px] 2xl:w-[550px] 2xl:h-[600px] pb-10"}
          //     crossPosition={
          //       "flex justify-end w-[16px] absolute left-[310px] sm:left-[555px] 2xl:left-[505px] top-3"
          //     }
        >
          <VerificationPopUp
            closePopup={() => setLink(false)}
            resendButton={true}
            firstMainHeading={"Connect your association profile"}
            // firstP={
            //   "Link your association to boost your business's visibility and build trust with customers."
            // }
            label={"Association Profile URL"}
            placeholder={"Paste the URL of your association profile here."}
            secondMainHeading={"Association profile linked"}
            secondP={
              "Success! Your association has been linked. This connection enhances credibility and helps your profile stand out."
            }
            smallPara={
              "By linking your association profile, you allow Trades Poster to display your profile on our platform, which may increase customer engagement. We assure you that your information is securely handled in compliance with privacy regulations."
            }
            buttonText={"Save"}
          />
        </Popup>
      )}
    </>
  );
};

export default GeneraComponet;
