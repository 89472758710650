/* eslint-disable default-case */
import React from "react";
import AuxiliaryText from "../../../../components/common/Typography/AuxiliaryText";

// ImageList = [
//   {
//     file
//     url
//   }
// ]
const PortfolioUploading = ({ setImageList, ImageList = [] }) => {
  console.log("ImageList", ImageList);
  const uploadFile = ({ target: { files } }) => {
    let data = new FormData();
    const filesData = [...files];
    setImageList([
      ...ImageList,
      ...filesData.map((file) => ({
        file: file,
        url: URL.createObjectURL(file),
      })),
    ]);
  };
  const deletImage = (index) => {
    setImageList(ImageList.filter((_, i) => i !== index));
  };
  const completion = 69.45;
  return (
    <>
      <div className="w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] mx-auto mt-5">
        <div className="flex">
          <label className={`block mb-2`}>
            <AuxiliaryText variant={"FieldLabel"}>Post Pictures</AuxiliaryText>
          </label>
          <i class="fi fi-rs-medical-star text-offblack text-[6px] ml-1"></i>
        </div>

        <div className="flex items-center justify-center w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px]">
          <input
            type="file"
            id="imageslist"
            onChange={uploadFile}
            accept="image/*"
            className="cursor-pointer hidden"
            multiple
          />
          <label
            role="button"
            htmlFor="imageslist"
            className="h-[140px] w-[320px] sm:w-[380px] md:w-[400px] lg:w-[420px] xl:w-[420px] 2xl:w-[500px] rounded-lg flex justify-center bg-pastalblue"
          >
            <div className="my-auto">
              <div className="flex justify-center">
                <div className="flex justify-center items-center">
                  <i className="fi fi-rs-inbox-out text-base text-offblack"></i>
                </div>
              </div>
              <div className="flex justify-center">
                <AuxiliaryText
                  variant={"Placeholder"}
                  className={"mt-1 text-primaryblue"}
                >
                  Click to upload
                </AuxiliaryText>
              </div>
            </div>
          </label>
        </div>
        <div className="mt-10 w-full">
          {ImageList.map((item, index) => (
            <div className="flex gap-3 w-full mt-5">
              {/* <i className="fi fi-br-picture text-[50px] text-primaryblue"></i> */}
              <div className="size-[50px]">
              <img alt={item.file.name} src={item.url} className="size-full object-fill rounded-lg" />

              </div>
              <div className="w-full flex flex-col gap-2 mt-5">
                <div className="flex items-center justify-between w-full">
                  <AuxiliaryText variant={"FieldLabel"}>
                    {item.file.name}
                  </AuxiliaryText>
                  <AuxiliaryText variant={"FieldLabel"}>
                    {completion}%
                  </AuxiliaryText>
                </div>
                <div className="w-full h-1.5 bg-white lg:bg-pastalblue rounded-full">
                  <div
                    className={`h-full bg-primaryblue rounded-full`}
                    style={{ width: `${completion}%` }}
                  ></div>
                </div>
              </div>
              <button type="button" onClick={() => deletImage(index)}>
                <i className="fi fi-rs-cross text-sm cursor-pointer mt-10"></i>
              </button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default PortfolioUploading;
