/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useMemo, useState } from "react";
import { IconContext } from "react-icons";
import { BsFillHeartFill as Heart } from "react-icons/bs";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import "swiper/css";
import { useServiceLikeMutation } from "../../../Hooks/Mutations/ServiceMutation/useLikeMutation";
import useAuth from "../../../Hooks/useAuth";
import Login from "../../../sections/Login/Login";
import { SignUp } from "../../../sections/SignUp/SignUp";
import "./card.css";
import SecondaryHeading from "../Typography/SecondaryHeading";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";
import MainButton from "../MainButton/MainButton";
import Title from "../../common/Title/Title";
import AuxiliaryText from "../Typography/AuxiliaryText";
import SpecialText from "../Typography/SpecialText";
import Pro from "../Tags/Pro";
const DashboardAdscard = ({
  sellerName,
  category,
  serviceName,
  serviceDescription,
  type,
  Images,
  rating,
  postTitle,
  companyLogo,
  serviceId,
  Col,
  companyId,
  replaceLink,
  reaction,
  selfWidth,
  additionalActions,
  viewButton,
  editButton,
  uploadButton,
  deleteButton,
  editOnClick,
  uploadOnClick,
  viewOnClick,
  deleteOnClick,
}) => {
  const id = useMemo(() => Math.round(Math.random() * 1000), []);
  const [heartClick, setheartClick] = useState(reaction);
  const navigate = useNavigate();
  const { auth } = useAuth();
  const [SignUpState, setSignUpState] = React.useState(false);
  const [SignIn, setSignIn] = React.useState(false);

  useEffect(() => {
    setheartClick(reaction);
  }, [reaction]);
  const { mutate } = useServiceLikeMutation();
  const handleLike = (serviceId, reaction) => {
    if (!auth?.user) {
      setSignIn(true);
    } else {
      setheartClick(!heartClick);
      mutate({
        userId: auth.user.id,
        serviceId: serviceId,
        reaction: !reaction,
      });
    }
  };
  const location = useLocation();
  return (
    <>
      {SignIn && (
        <Login
          location={location.pathname + location.search}
          SignIn={SignIn}
          setSignIn={setSignIn}
          SignUpState={SignUpState}
          setSignUpState={setSignUpState}
        />
      )}
      {SignUpState && (
        <SignUp
          location={location.pathname + location.search}
          SignUpState={SignUpState}
          setSignUpState={setSignUpState}
          SignIn={SignIn}
          setSignIn={setSignIn}
        />
      )}
      <div className="2xl:w-[480px] relative aspect-[480/360]">
        <div className="group/main cursor-pointer relative">
          <div
            className="div1 hidden group-hover/main:block absolute z-10 top-1/4 left-2 h-full 2xl:h-[360px]"
            onClick={() => {
              navigate({
                pathname: "/service-detail/",
                search: `?${createSearchParams({
                  id: serviceId,
                })}`,
              });
            }}
          >
            <div className="flex flex-col justify-between -mt-[15%] h-[80%] w-full">
              <div className="flex flex-col w-[40px]">
                <Title text={"Share"} width={"50"}>
                  <div className=" flex items-center justify-center bg-white rounded-full w-[30px] h-[30px] relative">
                    <Link>
                      <i className="fi fi-rs-share text-mediumgray cursor-pointer hover:text-primaryblue text-xs"></i>
                    </Link>
                  </div>
                </Title>
                <Title text={"Save"} width={"50"}>
                  <div className="flex items-center justify-center bg-white rounded-full  w-[30px] h-[30px] relative mt-1">
                    <span
                      onClick={(e) => {
                        handleLike(serviceId, reaction);
                      }}
                    >
                      <IconContext.Provider
                        value={{
                          className: `text-xs cursor-pointer duration-500 trasition-all hover:text-alertred  ${
                            heartClick === true
                              ? "text-alertred"
                              : "text-[#BDBEBF]"
                          } `,
                        }}
                      >
                        <Heart />
                      </IconContext.Provider>
                    </span>
                  </div>
                </Title>
              </div>
              {additionalActions && (
                <div className="flex items-center justify-center gap-x-1">
                  {editButton && (
                    <Title text={"Edit"} width={"50"}>
                      <div
                        onClick={editOnClick}
                        className="flex items-center justify-center bg-white rounded-full size-[30px] relative"
                      >
                        <i className="fi fi-rs-pen-circle text-xs text-mediumgray hover:text-primaryblue mt-[2px]"></i>
                      </div>
                    </Title>
                  )}
                  {uploadButton && (
                    <Title text={"Upload"} width={"50"}>
                      <div
                        onClick={uploadOnClick}
                        className="flex items-center justify-center bg-white rounded-full size-[30px] relative"
                      >
                        <i className="fi fi-rs-inbox-out text-xs text-mediumgray hover:text-freshgreen"></i>
                      </div>
                    </Title>
                  )}
                  {viewButton && (
                    <Title text={"View"} width={"50"}>
                      <div
                        onClick={viewOnClick}
                        className="flex items-center justify-center bg-white rounded-full size-[30px] relative"
                      >
                        <i className="fi fi-rs-eye text-xs text-mediumgray hover:text-offblack"></i>
                      </div>
                    </Title>
                  )}
                  {deleteButton && (
                    <Title text={"Delete"} width={"50"}>
                      <div
                        onClick={deleteOnClick}
                        className="flex items-center justify-center bg-white rounded-full size-[30px] relative"
                      >
                        <i className="fi fi-rs-trash text-xs text-mediumgray hover:text-alertred"></i>
                      </div>
                    </Title>
                  )}
                 
                </div>
              )}
              <div className="z-10 text-white w-[100%]">
                <div
                  className="flex gap-x-2 cursor-pointer"
                  onClick={() => {
                    navigate(
                      {
                        pathname: "/poster-profile/",
                        search: `?${createSearchParams({
                          id: companyId,
                          name: postTitle,
                        })}`,
                      },
                      { replace: replaceLink }
                    );
                  }}
                >
                  <div className="flex flex-col">
                    <SecondaryHeading
                      variant={"MainParagraphHeading"}
                      className={
                        "h-[40px] w-full overflow-hidden text-ellipsis"
                      }
                    >
                      {/* {postTitle?.split(/\s+/).slice(0, 3).join(" ")} */}
                      {postTitle?.substring(0, 100)}
                    </SecondaryHeading>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="relative w-full 2xl:w-[480px] 2xl:h-[360px] aspect-[480/360]">
            <img
              onClick={() => {
                navigate({
                  pathname: "/service-detail/",
                  search: `?${createSearchParams({
                    id: serviceId,
                  })}`,
                });
              }}
              className="cursor-pointer rounded-xl object-cover w-full h-full"
              src={[Images?.[0]] ?? require("../../../assets/img/default-image.jpg")}
              alt="product images"
              onError={(e) => {
                e.target.src = require("../../../assets/img/default-image.jpg");
              }}
            />
            <div className="absolute z-10 -mt-[73%] right-2">
              <div className="bg-primaryblue text-white px-[2px] pt-[1px] mt-1">
                <AuxiliaryText variant={"Infographic"}>Top Rated</AuxiliaryText>
              </div>
            </div>
          </div>
        </div>
        <div className="relative text-offblack">
          <div className="flex items-center justify-between w-full mt-3 h-[24px]">
            <div className="flex gap-x-3">
              <div className="w-[24px] group/profile relative">
                <img
                  className="object-center rounded-full size-6 cursor-pointer"
                  src={
                    !companyLogo || companyLogo === "null"
                      ? require("../../../assets/img/default-image.jpg")
                      : companyLogo
                  }
                  alt={"no-image"}
                  onError={(e) => {
                    e.target.src = require("../../../assets/img/default-image.jpg");
                  }}
                />
                <div className="hidden lg:group-hover/profile:block absolute z-20">
                  <div
                    className={`bg-white -ml-2 -mt-[240px] w-[350px] sm:w-[400px] h-[200px] rounded-xl p-5`}
                  >
                    <div className="flex items-center justify-between">
                      <div className="flex items-center">
                        <div className="relative w-[48px] h-[48px]">
                          <img
                            className="object-center rounded-full w-[48px] h-[48px] cursor-pointer"
                            src={require("../../../assets/img/User.png")}
                            // src={
                            //   !companyLogo || companyLogo === "null"
                            //     ? require("../../../assets/img/hero-vector.svg").default
                            //     : companyLogo
                            // }
                            alt={"no-image"}
                            // onError={(e) => {
                            //     e.target.src = require("../../assets/img/default-image.jpg");
                            //   }}
                          />
                          <div className="absolute -mt-3 left-[30%]">
                            <div className="mt-[1px] mr-3">
                              <Pro className={"bg-primaryblue text-white"} />
                            </div>
                          </div>
                        </div>
                        <div className="ml-3">
                          <div>
                            <SpecialText variant={"ProfileName"}>
                              Architecture
                              {/* {heading} */}
                            </SpecialText>
                          </div>
                          <div>
                            <SpecialText variant={"OccupationLocation"}>
                              Duffy, ACT, 2611
                            </SpecialText>
                          </div>
                        </div>
                      </div>
                      <div>
                        <MainButton variant={"outlineextrasmall"}>
                          Follow
                        </MainButton>
                      </div>
                    </div>
                    <div className="flex items-center justify-between mt-5">
                      <div className="w-[100px] sm:w-[109px] h-[82px]">
                        <img
                          src={require("../../../assets/img/default-image.jpg")}
                          className="w-full h-full rounded-xl object-center"
                          alt="portfolio"
                        />
                      </div>
                      <div className="w-[100px] sm:w-[109px] h-[82px]">
                        <img
                          src={require("../../../assets/img/default-image.jpg")}
                          className="w-full h-full rounded-xl object-center"
                          alt="portfolio"
                        />
                      </div>
                      <div className="w-[100px] sm:w-[109px] h-[82px]">
                        <img
                          src={require("../../../assets/img/default-image.jpg")}
                          className="w-full h-full rounded-xl object-center"
                          alt="portfolio"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="w-0 h-0 border-l-[10px] border-l-transparent border-t-[15px] border-t-white border-r-[10px] border-r-transparent ml-1"></div>
                </div>
              </div>
              <div className="flex items-center sm:items-start gap-x-2">
                <SpecialText
                  className={"overflow-hidden text-ellipsis text-offblack"}
                  variant={"ProfileName"}
                  onClick={() => {
                    navigate({
                      pathname: "/service-detail/",
                      search: `?${createSearchParams({
                        id: serviceId,
                      })}`,
                    });
                  }}
                >
                  Business Name
                  {/* {serviceName?.substring(0, 35)} a b c d e f d e f g h j k l k
                  k f g */}
                </SpecialText>
                <div>
                  <div className="mt-1">
                    <Pro
                      className={
                        "bg-mediumgray text-white hover:bg-primaryblue"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex items-center gap-x-2 h-[24px]">
              <div className="flex items-center">
                <div
                  onClick={(e) => {
                    handleLike(serviceId, reaction);
                  }}
                >
                  <IconContext.Provider
                    value={{
                      className: `size-[14px] sm:size-3 cursor-pointer duration-500 trasition-all ${
                        heartClick === true
                          ? "text-alertred"
                          : "text-[#DBDBDB] "
                      } `,
                    }}
                  >
                    <Heart />
                  </IconContext.Provider>
                </div>
                <SpecialText
                  variant={"Counter"}
                  className={"ml-1 text-offblack"}
                >
                  400
                </SpecialText>
              </div>
              <div className="flex items-center h-[24px]">
                <div
                  onClick={(e) => {
                    handleLike(serviceId, reaction);
                  }}
                >
                  <i className="fi fi-rs-eye text-sm sm:text-xs"></i>
                </div>
                <div>
                  <SpecialText
                    variant={"Counter"}
                    className={"ml-1 text-offblack"}
                  >
                    400
                  </SpecialText>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardAdscard;
