import React from "react";
import MainButton from "../../components/common/MainButton/MainButton";
import TextFiled from "../../components/common/InputField/TextFiled";
import TextArea from "../../components/common/InputField/TextArea";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import Heading from "../../components/common/Typography/Heading";
import Paragraph from "../../components/common/Typography/Paragraph";
import SpecialText from "../../components/common/Typography/SpecialText";
import InteractiveText from "../../components/common/Typography/InteractiveText";
import AuxiliaryText from "../../components/common/Typography/AuxiliaryText";
const VerificationPopUp = ({
  firstMainHeading,
  secondMainHeading,
  firstP,
  Headingh6,
  Headingh5,
  secondHeadingh6,
  secondHeadingh5,
  secondP,
  placeholder,
  label,
  closePopup,
  smallPara,
  resendButton,
  buttonText,
}) => {
  const [step, setStep] = useState(1);
  const location = useLocation();

  return (
    <>
      {step === 1 && (
        <div className="w-[300px] sm:w-[350px] md:w-[380px] lg:w-[380px] xl:w-[500px] 2xl:w-[500px] mx-auto mt-20 xl:mt-40">
          {!Headingh6 && (
            <Heading
              variant={"h6"}
              className={"text-center mb-10 text-offblack"}
            >
              {firstMainHeading}
            </Heading>
          )}
          {Headingh5 && (
            <Heading
              variant={"h5"}
              className={"text-center mb-10 text-offblack"}
            >
              {firstMainHeading}
            </Heading>
          )}
          <div className="mx-auto rounded-lg mb-[16px] w-[300px] sm:w-[350px] md:w-[380px] lg:w-[400px] xl:w-[400px] 2xl:w-[400px]">
            <div className="max-w-[400px] mx-auto">
              <Paragraph
                variant={"MainParagraph"}
                className={"text-offblack text-start"}
              >
                {firstP}
              </Paragraph>
            </div>

            <div className="mt-10 w-full">
              {location.pathname === "/business-dashboard/inbox/reviews" ? (
                <>
                  <TextArea
                    variant={"general"}
                    label={label}
                    placeholder={placeholder}
                  />
                </>
              ) : (
                <>
                  <TextFiled
                    variant={"general"}
                    label={label}
                    placeholder={placeholder}
                  />
                </>
              )}
            </div>

            <SpecialText
              variant={"FootNoteDisclaimer"}
              className={"text-start mt-5"}
            >
              {smallPara}
            </SpecialText>
            <div>
              {location.pathname ===
                "/business-dashboard/settings/social-profiles" ||
              "/business-dashboard/settings/associations" ||
              location.pathname.includes("/Review") ? (
                <>
                  <div className="flex items-center justify-end mt-10">
                    <MainButton
                      variant={"extrasmall"}
                      onClick={() => {
                        setStep(2);
                        setTimeout(closePopup, 3000);
                      }}
                    >
                      {buttonText}
                    </MainButton>
                  </div>
                </>
              ) : (
                <>
                  <div
                    className={
                      !resendButton
                        ? "flex items-center justify-between mt-10"
                        : "flex items-center justify-end mt-10"
                    }
                  >
                    {!resendButton && (
                      <div className="flex items-center">
                        <AuxiliaryText
                          variant={"Infographic"}
                          className={"mr-2"}
                        >
                          Didn’t get the code?
                        </AuxiliaryText>
                        <InteractiveText
                          variant={"ActionLink"}
                          className={"text-primaryblue cursor-pointer"}
                        >
                          Resend
                        </InteractiveText>
                      </div>
                    )}

                    <MainButton
                      variant={"extrasmall"}
                      onClick={() => {
                        setStep(2);
                        setTimeout(closePopup, 3000);
                      }}
                    >
                      Submit
                    </MainButton>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}

      {step === 2 && (
        <>
          <div className="w-[300px] sm:w-[350px] md:w-[380px] lg:w-[500px] xl:w-[500px] 2xl:w-[500px] mx-auto mt-20 xl:mt-40">
            {!secondHeadingh6 && (
              <Heading
                variant={"h6"}
                className={"text-center mb-10 text-offblack"}
              >
                {secondMainHeading}
              </Heading>
            )}
            {secondHeadingh5 && (
              <Heading
                variant={"h5"}
                className={"text-center mb-10 text-offblack"}
              >
                {secondMainHeading}
              </Heading>
            )}
            <div className="flex">
              <div className="w-[100%]">
                <div className="max-w-[400px] mx-auto">
                  <Paragraph
                    variant={"MainParagraph"}
                    className={"text-offblack text-start"}
                  >
                    {secondP}
                  </Paragraph>
                </div>
                <div className="flex justify-center mt-10">
                  <i className="fi fi-ss-badge-check text-[50px] text-primaryblue"></i>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default VerificationPopUp;
