import React from "react";
import { Link, useParams } from "react-router-dom";
import SecondaryHeading from "../../components/common/Typography/SecondaryHeading";
import Paragraph from "../../components/common/Typography/Paragraph";

const Regulators = () => {
  const { slug } = useParams();
  const data = [
    {
      slug: "architectural-services",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "National building codes.",
              link: "https://www.abcb.gov.au/",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Design standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "AIA",
              inlineParagraph: "Represents architects.",
              link: "https://www.architects.org.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Architects Board",
              inlineParagraph: "Licensing.",
              link: "https://www.architects.nsw.gov.au/",
            },
            {
              inlineHeading: "ARBV",
              inlineParagraph: "Compliance in Victoria.",
              link: "https://www.arbv.vic.gov.au/",
            },
            {
              inlineHeading: "BOAQ",
              inlineParagraph: "QLD standards.",
              link: "https://www.boaq.qld.gov.au/",
            },
            {
              inlineHeading: "WA Architects Board",
              inlineParagraph: "WA compliance.",
              link: "https://www.architectsboardwa.com.au/",
            },
            {
              inlineHeading: "SA Architects Board",
              inlineParagraph: "SA compliance.",
              link: "https://www.archboardsa.org.au/",
            },
            {
              inlineHeading: "CBOS Tasmania",
              inlineParagraph: "TAS regulation.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT compliance.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "AIA",
              inlineParagraph: "Advocacy and resources.",
              link: "https://www.architecture.com.au/",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Support in construction.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "AACA",
              inlineParagraph: "Architectural accreditation.",
              link: "https://aaca.org.au/",
            },
            {
              inlineHeading: "ACA",
              inlineParagraph: "Architectural contractors.",
              link: "https://aca.org.au/",
            },
            {
              inlineHeading: "AAA",
              inlineParagraph: "Architecture advocacy.",
              link: "https://www.architecture.org.au/",
            },
            {
              inlineHeading: "BDAA",
              inlineParagraph: "Building designers association.",
              link: "https://bdaa.com.au/",
            },
          ],
        },
        {
          listHeading: "Local councils",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local architectural rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "air-conditioning-and-heating",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "HVAC codes.",
              link: "https://www.abcb.gov.au/",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "HVAC standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Safety standards.",
              link: "https://www.safeworkaustralia.gov.au/",
            },
            {
              inlineHeading: "ARC",
              inlineParagraph: "Refrigerant compliance.",
              link: "https://www.arctick.org/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW licensing.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria compliance.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD standards.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "Building and Energy WA",
              inlineParagraph: "WA regulation.",
              link: "https://www.commerce.wa.gov.au/building-and-energy",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS standards.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT compliance.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT regulation.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "AIRAH",
              inlineParagraph: "Training & resources.",
              link: "https://www.airah.org.au/",
            },
            {
              inlineHeading: "AMCA",
              inlineParagraph: "Contractor network.",
              link: "https://www.amca.com.au/",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Construction support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "AREMA",
              inlineParagraph: "Refrigeration engineers.",
              link: "https://arema.com.au",
            },
            {
              inlineHeading: "ACACC",
              inlineParagraph: "Cooling contractors.",
              link: "https://www.acacc.com.au/",
            },
          ],
        },
        {
          listHeading: "Local councils",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local air conditioning & heating rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },

    {
      slug: "bricklaying-and-blockwork",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "Masonry codes.",
              link: "https://www.abcb.gov.au/",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Masonry standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Masonry safety.",
              link: "https://www.safeworkaustralia.gov.au/",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Industry body.",
              link: "https://www.masterbuilders.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD regulation.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA compliance.",
              link: "https://www.commerce.wa.gov.au/building-and-energy",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS regulation.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT compliance.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Professional support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Housing & masonry compliance.",
              link: "https://hia.com.au/",
            },
            {
              inlineHeading: "MCA",
              inlineParagraph: "Contractor association.",
              link: "https://masonrycontractors.com.au/",
            },
            {
              inlineHeading: "ABBTF",
              inlineParagraph: "Bricklaying careers.",
              link: "https://www.brickandblockcareers.org.au/",
            },
            {
              inlineHeading: "CMAA",
              inlineParagraph: "Masonry advocacy.",
              link: "https://www.cmaa.com.au/",
            },
          ],
        },
        {
          listHeading: "Local councils",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local bricklaying rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "cabinet-making",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "Sets codes for safe cabinetry.",
              link: "https://www.abcb.gov.au/",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Cabinet making standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "ACFA",
              inlineParagraph: "National representation.",
              link: "https://www.acfa.net.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW licensing.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria compliance.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD standards.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA standards.",
              link: "https://www.commerce.wa.gov.au/building-and-energy",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA compliance.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS Tasmania",
              inlineParagraph: "TAS regulation.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT compliance.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "ACFA",
              inlineParagraph: "Advocacy and resources.",
              link: "https://www.acfa.net.au/",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Support for cabinetry.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "CMAWA",
              inlineParagraph: "Cabinet makers association.",
              link: "https://www.cmawa.com.au/",
            },
          ],
        },
        {
          listHeading: "Local councils",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local cabinetry rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "carpentry",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "Timber construction standards.",
              link: "https://www.abcb.gov.au/",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Carpentry guidelines.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Nationwide representation.",
              link: "https://www.masterbuilders.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW",
              inlineParagraph: "Fair Trading.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VIC",
              inlineParagraph: "VBA compliance.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QLD",
              inlineParagraph: "QBCC standards.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "WA",
              inlineParagraph: "DMIRS regulation.",
              link: "https://www.commerce.wa.gov.au/building-and-energy",
            },
            {
              inlineHeading: "SA",
              inlineParagraph: "OTR safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "TAS",
              inlineParagraph: "CBOS regulation.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT",
              inlineParagraph: "Planning compliance.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT",
              inlineParagraph: "Building standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Professional support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Carpentry Australia",
              inlineParagraph: "Carpentry advocacy.",
              link: "https://www.carpentryaustralia.com.au/",
            },
            {
              inlineHeading: "MBA",
              inlineParagraph: "Builders association.",
              link: "https://masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Housing industry.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "Local councils",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local carpentry rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "carpet-and-flooring",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "Flooring safety standards.",
              link: "https://www.abcb.gov.au/",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Installation guidelines.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "FCAA",
              inlineParagraph: "National representation.",
              link: "https://www.fcaa.org.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "Licensing.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria compliance.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "Queensland standards.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA regulation.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS Tasmania",
              inlineParagraph: "TAS regulation.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "Compliance.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "FCAA",
              inlineParagraph: "Advocacy and resources.",
              link: "https://www.fcaa.org.au/",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Support for flooring.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "CIAL",
              inlineParagraph: "Carpet industry support and training.",
              link: "https://www.carpetinstitute.com.au/",
            },
            {
              inlineHeading: "FCIA",
              inlineParagraph: "Floor covering standards and industry advice.",
              link: "https://www.fcia.org.au/",
            },
          ],
        },
        {
          listHeading: "Local council guide",
          list: [
            {
              inlineHeading: "Local councils",
              inlineParagraph: "Find local regulations.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "caulking-services",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Sets guidelines for sealing materials.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Represents caulking in construction.",
              link: "https://www.masterbuilders.com.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "Manages licensing in NSW.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Oversees compliance in Victoria.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "Regulates standards in Queensland.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "Ensures compliance in WA.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "Oversees safety in SA.",
              link: "https://www.otr.sa.gov.au",
            },
            {
              inlineHeading: "CBOS Tasmania",
              inlineParagraph: "Manages regulations in TAS.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "Ensures compliance in ACT.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "Sets standards in NT.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Local councils guide",
          list: [
            {
              inlineHeading: "Local councils",
              inlineParagraph: "Access specific caulking regulations.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "cleaning-services",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Safety standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "Cleaning Council",
              inlineParagraph: "Representation.",
              link: "https://www.cleaningcouncil.org.au",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Builders' cleaning.",
              link: "https://www.masterbuilders.com.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW",
              inlineParagraph: "Fair Trading.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VIC",
              inlineParagraph: "VBA standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QLD",
              inlineParagraph: "QBCC regulations.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "WA",
              inlineParagraph: "DMIRS safety.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "SA",
              inlineParagraph: "OTR cleaning.",
              link: "https://www.otr.sa.gov.au",
            },
            {
              inlineHeading: "TAS",
              inlineParagraph: "CBOS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT",
              inlineParagraph: "Planning rules.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT",
              inlineParagraph: "Building standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "Cleaning Council",
              inlineParagraph: "Advocacy.",
              link: "https://www.cleaningcouncil.org.au",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Support.",
              link: "https://www.masterbuilders.com.au",
            },
            {
              inlineHeading: "BSCAA",
              inlineParagraph: "Professional support and training.",
              link: "https://bscaa.com/",
            },
            {
              inlineHeading: "NACLA",
              inlineParagraph: "Cleaning industry standards and resources.",
              link: "https://nacla.com.au",
            },
            {
              inlineHeading: "SRCP",
              inlineParagraph: "Specialized cleaning resources and training.",
              link: "https://srcp.org.au/",
            },
            {
              inlineHeading: "ISSA",
              inlineParagraph:
                "Global cleaning association with industry support.",
              link: "https://www.issa.com/",
            },
          ],
        },
        {
          listHeading: "Local council guide",
          list: [
            {
              inlineHeading: "Local councils",
              inlineParagraph: "Local cleaning rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "concrete-and-paving",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Concrete/paving standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Contractor representation.",
              link: "https://www.masterbuilders.com.au",
            },
            {
              inlineHeading: "ACRA",
              inlineParagraph: "Maintenance guidelines.",
              link: "https://www.acra.org.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW",
              inlineParagraph: "Fair Trading compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VIC",
              inlineParagraph: "VBA standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QLD",
              inlineParagraph: "QBCC compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "WA",
              inlineParagraph: "DMIRS standards.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "SA",
              inlineParagraph: "OTR compliance.",
              link: "https://www.sa.gov.au/otr",
            },
            {
              inlineHeading: "TAS",
              inlineParagraph: "CBOS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT",
              inlineParagraph: "Planning regulations.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT",
              inlineParagraph: "Building standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "ACRA",
              inlineParagraph: "Repair advocacy.",
              link: "https://www.acra.org.au",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Paving support.",
              link: "https://www.masterbuilders.com.au",
            },
            {
              inlineHeading: "CMAA",
              inlineParagraph: "Concrete maintenance association.",
              link: "https://www.cmaa.com.au/",
            },
            {
              inlineHeading: "CIA",
              inlineParagraph: "Concrete industry advocacy.",
              link: "https://concreteinstitute.com.au/",
            },
            {
              inlineHeading: "CCAA",
              inlineParagraph: "Concrete suppliers association.",
              link: "https://www.ccaa.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council guide",
          list: [
            {
              inlineHeading: "Local councils",
              inlineParagraph: "Local concrete rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "conveyancing",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "AIC",
              inlineParagraph: "National conveyancer support.",
              link: "https://www.aic.gov.au",
            },
            {
              inlineHeading: "ACCC",
              inlineParagraph: "Fair trading in property.",
              link: "https://www.accc.gov.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD oversight.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA regulation.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA compliance.",
              link: "https://www.sa.gov.au/topics/business-and-trade/otr",
            },
            {
              inlineHeading: "CBOS Tasmania",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "AIC State Branches",
              inlineParagraph: "State support.",
              link: "https://www.aic.gov.au/state-branches",
            },
            {
              inlineHeading: "Property Council",
              inlineParagraph: "Property advocacy.",
              link: "https://www.propertycouncil.com.au",
            },
            {
              inlineHeading: "REIA",
              inlineParagraph: "Transaction insights.",
              link: "https://www.reia.com.au",
            },
            {
              inlineHeading: "AIC",
              inlineParagraph: "National body for conveyancers.",
              link: "https://aicnational.com.au/",
            },
            {
              inlineHeading: "AIC Vic",
              inlineParagraph: "Support for conveyancers in Victoria.",
              link: "https://www.aicvic.com.au/",
            },
            {
              inlineHeading: "AICNSW",
              inlineParagraph: "Support for conveyancers in New South Wales.",
              link: "https://www.aicnsw.com.au/",
            },
            {
              inlineHeading: "AICWA",
              inlineParagraph: "Support for conveyancers in Western Australia.",
              link: "https://aicwa.com.au/",
            },
            {
              inlineHeading: "AICSA",
              inlineParagraph: "Support for conveyancers in South Australia.",
              link: "https://aicsa.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council conveyancing guide",
          list: [
            {
              inlineHeading: "Find local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "curtains-blinds-and-awnings",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "Codes for coverings.",
              link: "https://www.abcb.gov.au",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Standards compliance.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "BIAA",
              inlineParagraph: "Industry support.",
              link: "https://www.biaa.com.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA regulation.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA compliance.",
              link: "https://www.sa.gov.au/topics/business-and-trade/otr",
            },
            {
              inlineHeading: "CBOS Tasmania",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "BIAA State Branches",
              inlineParagraph: "State support.",
              link: "https://www.biaa.com.au",
            },
            {
              inlineHeading: "Master Builders",
              inlineParagraph: "Construction support.",
              link: "https://www.masterbuilders.com.au",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Housing industry advocacy.",
              link: "https://hia.com.au/",
            },
            {
              inlineHeading: "WSAA",
              inlineParagraph:
                "Advocacy for window shading, awnings, and other exterior coverings.",
              link: "https://www.wsaa.au/",
            },
          ],
        },
        {
          listHeading: "Local council window covering guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "decking-gazebo-and-carports",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Decking & gazebo standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "MBA",
              inlineParagraph: "Construction support.",
              link: "https://www.masterbuilders.com.au",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Residential compliance.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA regulation.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/otr",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Builders' support.",
              link: "https://www.masterbuilders.com.au",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Residential sector.",
              link: "https://hia.com.au/",
            },
            {
              inlineHeading: "DIA",
              inlineParagraph:
                "Support for decking industry professionals and standards.",
              link: "https://www.deckingindustry.com.au",
            },
            {
              inlineHeading: "ASI",
              inlineParagraph: "The Voice of Australian Steel",
              link: "https://www.steel.org.au",
            },
          ],
        },
        {
          listHeading: "Local council guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "demolition-and-excavation",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Demolition standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "MBA",
              inlineParagraph: "Industry support.",
              link: "https://www.mbansw.asn.au",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Safety rules.",
              link: "https://www.safeworkaustralia.gov.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/planning-and-property/otr",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Support.",
              link: "https://www.mbansw.asn.au",
            },
            {
              inlineHeading: "ADIA",
              inlineParagraph: "Industry body.",
              link: "https://www.adia.org.au",
            },
            {
              inlineHeading: "DAIAV",
              inlineParagraph:
                "Support and resources for demolition in Victoria.",
              link: "https://demolitionvictoria.com.au/",
            },
            {
              inlineHeading: "DAIA",
              inlineParagraph: "Support for demolition industry professionals.",
              link: "https://daia.com.au/",
            },
            {
              inlineHeading: "DIAWA",
              inlineParagraph:
                "Western Australia demolition industry association.",
              link: "https://diawa.au/",
            },
          ],
        },
        {
          listHeading: "Local council guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "electrical-services",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Electrical standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "MEA",
              inlineParagraph: "Best practices.",
              link: "https://www.mea.asn.au",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Safety rules.",
              link: "https://www.safeworkaustralia.gov.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/planning-and-property/otr",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MEA",
              inlineParagraph: "Electricians’ support.",
              link: "https://www.masterelectricians.com.au/",
            },
            {
              inlineHeading: "NECA",
              inlineParagraph: "Industry body.",
              link: "https://www.neca.asn.au",
            },
            {
              inlineHeading: "ETU",
              inlineParagraph: "Electricians’ rights and workplace advocacy.",
              link: "https://www.etu.org.au",
            },
            {
              inlineHeading: "ETU",
              inlineParagraph:
                "National union for electricians, advocating for worker rights.",
              link: "https://www.etunational.asn.au/",
            },
          ],
        },
        {
          listHeading: "Local council electrical guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "engineering-services",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "Building codes.",
              link: "https://www.abcb.gov.au",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Engineering standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "Engineers Australia",
              inlineParagraph: "Professional engineering body.",
              link: "https://www.engineersaustralia.org.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW licensing.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria compliance.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "BPEQ",
              inlineParagraph: "QLD registration.",
              link: "https://www.bpeq.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA regulation.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "Engineers Australia",
              inlineParagraph: "Engineering profession support.",
              link: "https://www.engineersaustralia.org.au",
            },
            {
              inlineHeading: "Consult Australia",
              inlineParagraph: "Engineering sector advocacy.",
              link: "https://www.consultaustralia.com.au",
            },
            {
              inlineHeading: "APEA",
              inlineParagraph: "Engineers’ professional support.",
              link: "https://www.professionalsaustralia.org.au/_engineers",
            },
            {
              inlineHeading: "ATSE",
              inlineParagraph: "Innovation through engineering.",
              link: "https://www.atse.org.au/",
            },
          ],
        },
        {
          listHeading: "Local council engineering guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },

    {
      slug: "fencing-and-gates",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Fencing standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Australia",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "AFIA",
              inlineParagraph: "Fencing industry body.",
              link: "https://www.afia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/planning-and-property",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "AFIA",
              inlineParagraph: "Fencing support.",
              link: "https://www.wireassociation.org.au/afia/afia-home",
            },
            {
              inlineHeading: "MBA",
              inlineParagraph: "Builders' network.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "FIA",
              inlineParagraph: "Fencing industry body.",
              link: "https://fencingindustryaustralia.com.au/",
            },
            {
              inlineHeading: "NSSA",
              inlineParagraph: "Security fencing.",
              link: "https://www.nssa.org.au/",
            },
          ],
        },
        {
          listHeading: "Local council fencing & gate guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "glass-and-glazing",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Glazing standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Glaziers Australia",
              inlineParagraph: "Best practices.",
              link: "https://www.mga.org.au/",
            },
            {
              inlineHeading: "AGGA",
              inlineParagraph: "Glass guidelines.",
              link: "https://www.agga.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/planning-and-property",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MGA",
              inlineParagraph: "Glazier support.",
              link: "https://www.mga.org.au/",
            },
            {
              inlineHeading: "AGWA",
              inlineParagraph: "Glass & window association.",
              link: "https://agwa.com.au",
            },
          ],
        },
        {
          listHeading: "Local council glazing & screen guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "garden-and-landscaping",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Landscaping standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "LIA",
              inlineParagraph: "Best practices.",
              link: "https://www.landscape.net.au/",
            },
            {
              inlineHeading: "AILA",
              inlineParagraph: "Environmental compliance.",
              link: "https://www.aila.org.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/planning-and-property",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "LIA",
              inlineParagraph: "Landscaping support.",
              link: "https://www.landscape.net.au/",
            },
            {
              inlineHeading: "AILA",
              inlineParagraph: "Design advocacy.",
              link: "https://www.aila.org.au/",
            },
            {
              inlineHeading: "LVML",
              inlineParagraph: "Landscaping Victoria.",
              link: "https://www.landscapingvictoria.com.au/",
            },
            {
              inlineHeading: "TLA",
              inlineParagraph: "Landscape association.",
              link: "https://www.landscapeassociation.com.au/",
            },
            {
              inlineHeading: "LDI",
              inlineParagraph: "Landscape designers.",
              link: "https://www.ldi.org.au/",
            },
          ],
        },
        {
          listHeading: "Local council landscaping guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "handyman-services",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Maintenance standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Australia",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Housing Industry Association",
              inlineParagraph: "Handyman advocacy.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.commerce.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-employment/occupational-health-and-safety",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Handyman support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Handyman services.",
              link: "https://hia.com.au/",
            },
            {
              inlineHeading: "ACHP",
              inlineParagraph: "Handyman association.",
              link: "https://handymanassociation.org/",
            },
          ],
        },
        {
          listHeading: "Local council handyman guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "inspections-and-surveyors",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Surveying standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "AIBS",
              inlineParagraph: "Surveyor support.",
              link: "https://www.aibs.com.au/",
            },
            {
              inlineHeading: "SSSI",
              inlineParagraph: "Surveying professionals.",
              link: "https://www.ssi.org.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.commerce.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-employment/occupational-health-and-safety",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "AIBS",
              inlineParagraph: "Surveyor support.",
              link: "https://aibs.com.au/",
            },
            {
              inlineHeading: "SSSI",
              inlineParagraph: "Surveying representation.",
              link: "https://www.ssi.org.au/",
            },
            {
              inlineHeading: "MIAA",
              inlineParagraph: "Inspector association.",
              link: "https://masterinspector.org.au/",
            },
            {
              inlineHeading: "ASBC",
              inlineParagraph: "Surveying body.",
              link: "https://www.asbc-vic.com.au/",
            },
            {
              inlineHeading: "BIAWA",
              inlineParagraph: "Building inspector support.",
              link: "https://www.biawa.org.au/",
            },
          ],
        },
        {
          listHeading: "Local council inspection & surveying guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "insulation-services",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Insulation standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "AIMG",
              inlineParagraph: "Manufacturer support.",
              link: "https://www.aims.org.au/",
            },
            {
              inlineHeading: "Clean Energy Council",
              inlineParagraph: "Sustainable practices.",
              link: "https://www.cleanenergycouncil.org.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-trade/consumer-protection/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "AIMG",
              inlineParagraph: "Insulation advocacy.",
              link: "https://www.aims.org.au/",
            },
            {
              inlineHeading: "CEC",
              inlineParagraph: "Energy & insulation.",
              link: "https://www.cleanenergycouncil.org.au",
            },
            {
              inlineHeading: "AIIC",
              inlineParagraph: "Insulation coalition.",
              link: "https://insulationcoalition.org/",
            },
            {
              inlineHeading: "ICANZ",
              inlineParagraph: "Insulation standards.",
              link: "https://icanz.org.au/",
            },
            {
              inlineHeading: "TICA",
              inlineParagraph: "Insulation contractors.",
              link: "https://tica.org.au/",
            },
          ],
        },
        {
          listHeading: "Local council insulation guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "interior-design",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Accessibility, Acoustics.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "DIA",
              inlineParagraph: "Interior design rep.",
              link: "https://www.dia.org.au",
            },
            {
              inlineHeading: "GBCA",
              inlineParagraph: "Sustainable design.",
              link: "https://www.gbca.org.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria safety.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD standards.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA compliance.",
              link: "https://www.sa.gov.au/topics/business-and-trade/consumer-protection/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS guidelines.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "DIA",
              inlineParagraph: "Professional support.",
              link: "https://www.design.org.au/",
            },
            {
              inlineHeading: "GBCA",
              inlineParagraph: "Green practices.",
              link: "https://www.gbca.org.au",
            },
            {
              inlineHeading: "AIDA",
              inlineParagraph: "Design excellence.",
              link: "https://www.aida.org.au",
            },
          ],
        },
        {
          listHeading: "Local council interior design guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "locksmiths",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Lock & security standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "MLAA",
              inlineParagraph: "Locksmith body.",
              link: "https://www.mlaa.org.au",
            },
            {
              inlineHeading: "SPAAL",
              inlineParagraph: "Security compliance.",
              link: "https://spaal.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD regulation.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-trade/consumer-protection/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MLAA",
              inlineParagraph: "Locksmith support.",
              link: "https://industry.masterlocksmiths.com.au/",
            },
            {
              inlineHeading: "SPAAL",
              inlineParagraph: "Security services.",
              link: "https://spaal.com.au/",
            },
            {
              inlineHeading: "LGA",
              inlineParagraph: "Local Government.",
              link: "https://www.lga.org.au/",
            },
            {
              inlineHeading: "ALA",
              inlineParagraph: "Locksmith services.",
              link: "https://www.australianlocksmithsassociation.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council locksmith guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "painting-and-decorating",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Painting standards & safety.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Painters Australia",
              inlineParagraph: "Industry representation.",
              link: "https://www.masterpainters.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Compliance support.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.cbs.sa.gov.au/otr",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "Master Painters Australia",
              inlineParagraph: "Professional support.",
              link: "https://masterpainters.org.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Industry advocacy.",
              link: "https://hia.com.au/",
            },
            {
              inlineHeading: "MP&DA",
              inlineParagraph:
                "Support and standards for painters and decorators.",
              link: "https://masterpainters.asn.au/",
            },
          ],
        },
        {
          listHeading: "Local council painting & decoration guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "moving-and-delivery",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "AFRA",
              inlineParagraph: "Moving standards & accreditation.",
              link: "https://www.afra.com.au/",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Safety compliance.",
              link: "https://www.safeworkaustralia.gov.au/",
            },
            {
              inlineHeading: "ACCC",
              inlineParagraph: "Consumer protection.",
              link: "https://www.accc.gov.au/",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "AFRA",
              inlineParagraph: "Professional movers.",
              link: "https://www.afra.com.au/",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Workplace safety.",
              link: "https://www.safeworkaustralia.gov.au/",
            },
          ],
        },
        {
          listHeading: "Local council moving guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules & permits.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "mould-and-asbestos",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Asbestos safety.",
              link: "https://www.safeworkaustralia.gov.au/",
            },
            {
              inlineHeading: "NATA",
              inlineParagraph: "Testing accreditation.",
              link: "https://www.nata.com.au/",
            },
            {
              inlineHeading: "EnHealth",
              inlineParagraph: "Mould management.",
              link: "https://www.health.gov.au/health-topics/environmental-health/enhealth",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.cbs.sa.gov.au/otr",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "NATA",
              inlineParagraph: "Testing support.",
              link: "https://www.nata.com.au/",
            },
            {
              inlineHeading: "EnHealth",
              inlineParagraph: "Indoor air quality.",
              link: "https://www.health.gov.au/health-topics/environmental-health/enhealth",
            },
            {
              inlineHeading: "RIA",
              inlineParagraph: "Restoration industry.",
              link: "https://www.restorationindustry.org.au/",
            },
            {
              inlineHeading: "IICRC",
              inlineParagraph: "Certification body.",
              link: "https://www.iicrc.org/",
            },
            {
              inlineHeading: "AHCA",
              inlineParagraph: "Asbestos safety.",
              link: "https://ahca.org.au/",
            },
            {
              inlineHeading: "ASEA",
              inlineParagraph: "Asbestos safety.",
              link: "https://www.asbestossafety.gov.au/",
            },
          ],
        },
        {
          listHeading: "Local council mould & asbestos guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "pest-control",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Pest control standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "AEPMA",
              inlineParagraph: "Industry representation.",
              link: "https://www.aepma.com.au/",
            },
            {
              inlineHeading: "NPMA",
              inlineParagraph: "Compliance support.",
              link: "https://www.pestworld.org/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-trade/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "AEPMA",
              inlineParagraph: "Pest management support.",
              link: "https://www.aepma.com.au/",
            },
            {
              inlineHeading: "NPMA",
              inlineParagraph: "Safety & compliance.",
              link: "https://www.pestworld.org/",
            },
          ],
        },
        {
          listHeading: "Local council pest control guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "solid-plastering",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Plastering standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Plasterers Australia",
              inlineParagraph: "Industry representation.",
              link: "https://www.masterplasterers.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-trade/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "Master Plasterers Australia",
              inlineParagraph: "Professional support.",
              link: "https://www.masterplasterers.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Plastering advocacy.",
              link: "https://hia.com.au/",
            },
            {
              inlineHeading: "AWCI",
              inlineParagraph: "Industry networking.",
              link: "https://awci.org.au/",
            },
            {
              inlineHeading: "MPA",
              inlineParagraph: "Painting & plastering support.",
              link: "https://masterpainters.org.au/",
            },
          ],
        },
        {
          listHeading: "Local council plastering guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "plumbing",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "ABCB",
              inlineParagraph: "National plumbing codes.",
              link: "https://www.abcb.gov.au/",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Plumbing standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Workplace safety.",
              link: "https://www.safeworkaustralia.gov.au/",
            },
            {
              inlineHeading: "Master Plumbers Australia",
              inlineParagraph: "Industry body.",
              link: "https://www.masterplumbers.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD regulation.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA compliance.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-trade/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS regulation.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT compliance.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "Master Plumbers",
              inlineParagraph: "Professional support.",
              link: "https://www.masterplumbers.com.au/",
            },
            {
              inlineHeading: "Plumbing Industry Association",
              inlineParagraph: "Advocacy & training.",
              link: "https://www.plumber.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Housing & plumbing compliance.",
              link: "https://hia.com.au/",
            },
            {
              inlineHeading: "NFIA",
              inlineParagraph: "Fire safety systems.",
              link: "https://www.nfia.com.au/",
            },
            {
              inlineHeading: "MPANZ",
              inlineParagraph: "Plumbing support.",
              link: "https://masterplumbersanz.com/",
            },
            {
              inlineHeading: "MPMSAA",
              inlineParagraph: "Industry services.",
              link: "https://plumber.com.au/",
            },
            {
              inlineHeading: "MPAQ",
              inlineParagraph: "Plumbing advocacy.",
              link: "https://www.mpaq.com.au/",
            },
            {
              inlineHeading: "MPASA",
              inlineParagraph: "Support for plumbers.",
              link: "https://www.mpasa.com.au/",
            },
            {
              inlineHeading: "MPGA",
              inlineParagraph: "Plumbing industry association.",
              link: "https://www.masterplumbers.asn.au/",
            },
            {
              inlineHeading: "IPA",
              inlineParagraph: "Plumbing standards.",
              link: "https://plumbing.org.au/",
            },
          ],
        },
        {
          listHeading: "Local council plumbing guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "pools-and-spas",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Pool & spa standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "SPASA",
              inlineParagraph: "Industry compliance.",
              link: "https://www.spasa.com.au/",
            },
            {
              inlineHeading: "RLSSA",
              inlineParagraph: "Drowning prevention.",
              link: "https://www.royallifesaving.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-trade/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "SPASA",
              inlineParagraph: "Pool & spa support.",
              link: "https://www.spasa.com.au/",
            },
            {
              inlineHeading: "RLSSA",
              inlineParagraph: "Water safety.",
              link: "https://www.royallifesaving.com.au/",
            },
            {
              inlineHeading: "MPBAA",
              inlineParagraph: "Industry body.",
              link: "https://www.mpbaa.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council pool & spa guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "rendering",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Rendering standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Association",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Housing Industry Association",
              inlineParagraph: "Compliance advocacy.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-trade/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Rendering support.",
              link: "https://masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Building compliance.",
              link: "https://hia.com.au/",
            },
            {
              inlineHeading: "AWCI",
              inlineParagraph: "Industry association.",
              link: "https://awci.org.au/",
            },
          ],
        },
        {
          listHeading: "Local council rendering guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "renovation-and-restoration",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Building & restoration standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Association",
              inlineParagraph: "Renovation support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Housing Industry Association",
              inlineParagraph: "Compliance in renovations.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-trade/office-of-the-regulator",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Building support.",
              link: "https://masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Renovation advocacy.",
              link: "https://hia.com.au/",
            },
            {
              inlineHeading: "RIA",
              inlineParagraph: "Restoration support.",
              link: "https://www.restorationindustry.org.au/",
            },
          ],
        },
        {
          listHeading: "Local council renovation & restoration guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "roofing",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Roofing standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Association",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Housing Industry Association",
              inlineParagraph: "Compliance & safety.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Roofing professionals.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Roofing advocacy.",
              link: "https://hia.com.au/",
            },
            {
              inlineHeading: "MRCAA",
              inlineParagraph: "Roofing professionals.",
              link: "https://www.mrcaa.com.au/",
            },
            {
              inlineHeading: "MRIAQ",
              inlineParagraph: "Roofing professionals.",
              link: "https://mriaq.asn.au/",
            },
            {
              inlineHeading: "ARTA",
              inlineParagraph: "Roofing industry support.",
              link: "https://www.australianchamber.com.au/associates/australian-roofing-tile-association/",
            },
          ],
        },
        {
          listHeading: "Local council roofing guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "scaffolding",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Scaffolding standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Association",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Safety compliance.",
              link: "https://www.safeworkaustralia.gov.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Scaffolding professionals.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Safe Work Australia",
              inlineParagraph: "Workplace safety.",
              link: "https://www.safeworkaustralia.gov.au/",
            },
            {
              inlineHeading: "SAA",
              inlineParagraph: "Scaffolding industry support.",
              link: "https://scaffolding-association.au/",
            },
          ],
        },
        {
          listHeading: "Local council scaffolding guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "security-and-automation",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Security & automation standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "ASIAL",
              inlineParagraph: "Security industry body.",
              link: "https://www.asial.com.au/",
            },
            {
              inlineHeading: "Master Electricians Australia",
              inlineParagraph: "Electrical & automation support.",
              link: "https://www.masterelectricians.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.commerce.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.sa.gov.au/topics/business-and-employment/occupational-health-and-safety",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS rules.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "ASIAL",
              inlineParagraph: "Security professionals.",
              link: "https://asial.com.au/",
            },
            {
              inlineHeading: "MEA",
              inlineParagraph: "Electrical & automation specialists.",
              link: "https://www.masterelectricians.com.au/",
            },
            {
              inlineHeading: "KNX Australia",
              inlineParagraph: "Automation and smart building solutions.",
              link: "https://knx.org.au/",
            },
            {
              inlineHeading: "IoTAA",
              inlineParagraph: "Internet of Things & automation technologies.",
              link: "https://iot.org.au/",
            },
          ],
        },
        {
          listHeading: "Local council security & automation guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "shopfitting",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Shopfitting standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Association",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Housing Industry Association",
              inlineParagraph: "Compliance & safety.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Shopfitting professionals.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Industry advocacy.",
              link: "https://hia.com.au/",
            },
            {
              inlineHeading: "FIA",
              inlineParagraph: "Fitout and shopfitting industry.",
              link: "https://www.interiorfitoutassociation.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council shopfitting guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "skip-bins",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Waste & bin standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "EPA",
              inlineParagraph: "Environmental safety.",
              link: "https://www.epa.gov.au",
            },
            {
              inlineHeading: "WCRA",
              inlineParagraph: "Waste management advocacy.",
              link: "https://www.wcra.com.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW EPA",
              inlineParagraph: "NSW compliance.",
              link: "https://www.epa.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DWER WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dwer.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.cbs.sa.gov.au",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "WCRA",
              inlineParagraph: "Waste professionals.",
              link: "https://www.wcra.com.au",
            },
            {
              inlineHeading: "EPA",
              inlineParagraph: "Environmental protection.",
              link: "https://www.epa.gov.au",
            },
            {
              inlineHeading: "ACOR",
              inlineParagraph: "Industry leaders in waste management.",
              link: "https://www.acor.org.au/",
            },
          ],
        },
        {
          listHeading: "Local council skip bin guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "solar-power",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Clean Energy Council",
              inlineParagraph: "Accreditation & best practices.",
              link: "https://www.cleanenergycouncil.org.au",
            },
            {
              inlineHeading: "Australian Energy Regulator",
              inlineParagraph: "Energy regulation.",
              link: "https://www.aer.gov.au",
            },
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Solar safety standards.",
              link: "https://www.standards.org.au",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.cbs.sa.gov.au",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "CEC",
              inlineParagraph: "Solar industry support.",
              link: "https://cleanenergycouncil.org.au/",
            },
            {
              inlineHeading: "AER",
              inlineParagraph: "Energy compliance.",
              link: "https://www.aer.gov.au/",
            },
            {
              inlineHeading: "SEC",
              inlineParagraph: "Smart energy advocacy.",
              link: "https://smartenergy.org.au/",
            },
            {
              inlineHeading: "APVI",
              inlineParagraph: "Australian PV Institute.",
              link: "https://apvi.org.au/",
            },
            {
              inlineHeading: "SEIA",
              inlineParagraph: "Solar Energy Industry Association.",
              link: "https://seia.org.au/",
            },
          ],
        },
        {
          listHeading: "Local council solar energy guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "staircase-services",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Staircase standards.",
              link: "https://www.standards.org.au",
            },
            {
              inlineHeading: "Master Builders Association",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au",
            },
            {
              inlineHeading: "Housing Industry Association",
              inlineParagraph: "Compliance in construction.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.cbs.sa.gov.au",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Builders' network.",
              link: "https://www.masterbuilders.com.au",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Industry advocacy.",
              link: "https://hia.com.au/",
            },
            {
              inlineHeading: "SABAA",
              inlineParagraph: "Staircase & balustrade association.",
              link: "https://theaba.asn.au",
            },
          ],
        },
        {
          listHeading: "Local council staircase guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "stonework",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Stonemasonry standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Association",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Housing Industry Association",
              inlineParagraph: "Compliance in stonemasonry.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Stonemason network.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Industry advocacy.",
              link: "https://hia.com.au/",
            },
            {
              inlineHeading: "ASAA",
              inlineParagraph: "Australian Stone Industry Association.",
              link: "https://asaa.com.au/",
            },
            {
              inlineHeading: "MSMAV",
              inlineParagraph: "Masonry industry specialists.",
              link: "https://msma.org.au/",
            },
          ],
        },
        {
          listHeading: "Local council stonework guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "tiling",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Tiling standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "Master Builders Association",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Housing Industry Association",
              inlineParagraph: "Compliance & safety.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA rules.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Tiler network.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Industry advocacy.",
              link: "https://hia.com.au/",
            },
            {
              inlineHeading: "ATC",
              inlineParagraph: "Australian Tile Council.",
              link: "https://www.australiantilecouncil.com.au/",
            },
          ],
        },
        {
          listHeading: "Local council tiling guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
    {
      slug: "waterproofing",
      regulators: [
        {
          listHeading: "National regulators",
          list: [
            {
              inlineHeading: "Standards Australia",
              inlineParagraph: "Waterproofing standards.",
              link: "https://www.standards.org.au/",
            },
            {
              inlineHeading: "National Construction Code",
              inlineParagraph: "National compliance.",
              link: "https://www.abcb.gov.au/",
            },
            {
              inlineHeading: "Master Builders Association",
              inlineParagraph: "Industry support.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "Housing Industry Association",
              inlineParagraph: "Compliance advocacy.",
              link: "https://hia.com.au/",
            },
          ],
        },
        {
          listHeading: "State regulators",
          list: [
            {
              inlineHeading: "NSW Fair Trading",
              inlineParagraph: "NSW compliance.",
              link: "https://www.fairtrading.nsw.gov.au/",
            },
            {
              inlineHeading: "VBA",
              inlineParagraph: "Victoria standards.",
              link: "https://www.vba.vic.gov.au/",
            },
            {
              inlineHeading: "QBCC",
              inlineParagraph: "QLD compliance.",
              link: "https://www.qbcc.qld.gov.au/",
            },
            {
              inlineHeading: "DMIRS WA",
              inlineParagraph: "WA regulations.",
              link: "https://www.dmirs.wa.gov.au/",
            },
            {
              inlineHeading: "OTR SA",
              inlineParagraph: "SA safety.",
              link: "https://www.otr.sa.gov.au/",
            },
            {
              inlineHeading: "CBOS TAS",
              inlineParagraph: "TAS compliance.",
              link: "https://www.cbos.tas.gov.au/",
            },
            {
              inlineHeading: "ACT Planning",
              inlineParagraph: "ACT standards.",
              link: "https://www.planning.act.gov.au/",
            },
            {
              inlineHeading: "NT Building Advisory",
              inlineParagraph: "NT standards.",
              link: "https://nt.gov.au/property/building",
            },
          ],
        },
        {
          listHeading: "Associations",
          list: [
            {
              inlineHeading: "MBA",
              inlineParagraph: "Waterproofing professionals.",
              link: "https://www.masterbuilders.com.au/",
            },
            {
              inlineHeading: "HIA",
              inlineParagraph: "Industry advocacy.",
              link: "https://hia.com.au/",
            },
            {
              inlineHeading: "AIW",
              inlineParagraph: "Waterproofing industry support.",
              link: "https://waterproof.org.au",
            },
          ],
        },
        {
          listHeading: "Local council waterproofing guide",
          list: [
            {
              inlineHeading: "Find your local council",
              inlineParagraph: "Local rules.",
              link: "/councils-regulations",
            },
          ],
        },
      ],
    },
  ];
  const categoryData = data.filter((item) => item.slug === slug);
  return (
    <>
      {categoryData.map((item, index) => {
        // Calculate the total number of top-level regulators
        const totalRegulators = item.regulators?.length || 0;
        console.log("Items", totalRegulators);
        return (
          <div
            key={index}
            className="text-offblack mt-10 mx-[5%] xl:mx-auto xl:w-[1075px] 2xl:w-[1440px] px-5"
          >
            {item.regulators && (
              <div
                className={
                  totalRegulators === 3
                    ? "grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 justify-between space-y-10 xl:space-y-0"
                    : "grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-4 justify-between space-y-10 xl:space-y-0 xl:space-x-10"
                }
              >
                {item.regulators.map((regulator, regulatorIndex) => (
                  <div key={regulatorIndex} className="w-fit">
                    <SecondaryHeading
                      variant={"MainParagraphHeading"}
                      className={"inline font-semibold"}
                    >
                      {regulator.listHeading}
                    </SecondaryHeading>

                    {regulator.list && (
                      <ul className="flex flex-col mt-3 space-y-2">
                        {regulator.list.map((listItem, itemIndex) => (
                          <li className={"inline"} key={itemIndex}>
                            <Link
                              className={
                                listItem.link
                                  ? "hover:text-primaryblue"
                                  : "cursor-default"
                              }
                              to={listItem.link}
                              target="_blank"
                            >
                              <SecondaryHeading
                                variant={"MainParagraphHeading"}
                                className={"inline font-semibold"}
                              >
                                {listItem.inlineHeading}
                              </SecondaryHeading>
                            </Link>
                            <Paragraph
                              variant={"MainParagraph"}
                              className={"inline text-offblack"}
                            >
                              &nbsp;&nbsp;{listItem.inlineParagraph}
                            </Paragraph>
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        );
      })}
    </>
  );
};

export default Regulators;
