import * as Yup from "yup";

export const defaultValues = {
  ABN: "",
  companyName: "",
  businessName: "",
  dropdown1: [],
  dropdown2: [],
  location: "",
  officeNumber: "",
  mobileNumber: "",
  businessEmail: "",
  businessWebsite: "",
  tradeSkills: [],
  businessDescription: ""
};

export const validationSchema = Yup.object({
  ABN: Yup.string()
    .matches(
      /^\d{11}$/,
      "ABN must be exactly 11 digits long and contain only numbers"
    ) // Ensures exactly 11 digits
    .required("ABN is required"),
  companyName: Yup.string()
    .max(20, "Company name must be less than or equal to 20 characters")
    .required("Company name is required"),
  businessName: Yup.string()
    .max(20, "Business name must be less than or equal to 20 characters")
    .required("Business name is required"),
  dropdown1: Yup.array().required("Primary category is required"),
  dropdown2: Yup.array(),
  location: Yup.string().required("Please tell about your location"),
  officeNumber: Yup.string()
  .matches(/^\d{10}$/, 'Office number must be exactly 10 digits long and contain only numbers') ,// Matches 10 digits
  mobileNumber: Yup.string()
  .matches(/^04\d{8}$/, 'Mobile number must start with 04 and be exactly 10 digits long')
  .required('Mobile number is required'),
  businessEmail: Yup.string()
  .email('Invalid email format'),
  businessWebsite: Yup.string()
  .url('Invalid website URL format'),
  businessDescription: Yup.string()
  .min(20, 'Business description must be at least 10 characters long')
  .max(400, 'Business description cannot be longer than 500 characters')
  .required('Business description is required')
});
