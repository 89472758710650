import React from "react";
import Heading from "../../components/common/Typography/Heading";
import PricingSlider from "../BusinessDashboard/Subscriptions/PricingPlans/PricingSlider";
import PriceCard from "../../components/common/PriceCard/PriceCard";

const HeroImage = () => {

  return (
    <>
      <div className="bg-about bg-no-repeat bg-center object-cover bg-cover h-[1000px] lg:h-screen">
        <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] pt-20 2xl:pt-28">
          <Heading
            variant={"h1"}
            className={"text-center text-white"}
          >
            Choose Your Plan
          </Heading>
        </div>
        <div className="flex justify-center lg:hidden mt-10 md:mt-20 mx-auto w-full sm:w-[550px] md:w-[650px] lg:w-[900px] xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px]">
          <PricingSlider />
        </div>
        <div className="hidden lg:grid grid-cols-3 gap-4 xl:gap-8 mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px] 3xl:w-[1400px] mt-10 xl:mt-20 2xl:mt-10">
          {/* Free Card */}
          <div className="col-span-3 md:col-span-1 lg:col-span-1">
            <PriceCard
              bottomLine={"border-b border-b-lightgray pb-5"}
              MainDiveClasses={
                "bg-white text-offblack w-full h-[550px] lg:w-full 2xl:w-[400px] 2xl:h-[650px] 3xl:w-[450px] pb-5 sm:pb-10 xl:pb-[50px]"
              }
              listClasses={"pl-[30px] 2xl:pl-[50px]"}
              pricingColor={"text-offblack"}
              tagging={true}
              label={"Free"}
              price={"00"}
              duration={1}
              description={
                "Build your profile, connect with customers, and showcase your services, for free."
              }
              buttonText={"Sign up for Free"}
              listItems={[
                `Basic profile`,
                `1 Keyword`,
                `1 Regular post`,
                `Messaging via platform`,
                `Social profile link`,
                `Multiple categories`
              ]}
              // listItemsValue={[
              //   `${getPremiumPackageDetail?.regularListing}`,
              //   `${getPremiumPackageDetail?.featureListing}`,
              //   `${getPremiumPackageDetail?.noOfImages}`,
              // ]}
            />
          </div>
          {/* Standard Card */}
          <div className="col-span-3 md:col-span-1 lg:col-span-1">
            <PriceCard
              bottomLine={"border-b border-b-lightgray pb-5"}
              MainDiveClasses={
                "bg-white text-offblack w-full h-[550px] lg:w-full 2xl:w-[400px] 2xl:h-[650px] 3xl:w-[450px] pb-5 sm:pb-10 xl:pb-[50px]"
              }
              listClasses={"pl-[30px] 2xl:pl-[50px]"}
              pricingColor={"text-offblack"}
              tagging={true}
              label={"Basic"}
              price={"XX"}
              duration={1}
              description={
                "Upgrade to the Basic Plan and unlock more tools to grow your visibility and engage customers."
              }
              buttonText={"Upgrade to Basic"}
              listItems={[
                `All free features`,
                `3 Keywords`,
                // `1 Featured post`,
                `Unlimited regular posts`,
                `Limited portfolio gallery`,
                `Direct contact details`,
                `Certifications & success stories`,
              ]}
              // listItemsValue={[
              //   `${getPremiumPackageDetail?.regularListing}`,
              //   `${getPremiumPackageDetail?.featureListing}`,
              //   `${getPremiumPackageDetail?.noOfImages}`,
              // ]}
            />
          </div>
          {/* Premium Card */}
          <div className="col-span-3 md:col-span-1 lg:col-span-1">
            <PriceCard
              bottomLine={"border-b border-b-lightgray pb-5"}
              MainDiveClasses={
                "bg-white text-offblack w-full h-[550px] lg:w-full 2xl:w-[400px] 2xl:h-[650px] 3xl:w-[450px] pb-5 sm:pb-10 xl:pb-[50px]"
              }
              listClasses={"pl-[30px] 2xl:pl-[50px]"}
              pricingColor={"text-offblack"}
              label={"Pro"}
              price={"XX"}
              duration={1}
              description={
                "Gain unmatched visibility, stand out, and grow your business with Pro’s advanced features."
              }
              buttonText={"Upgrade to Pro"}
              listItems={[
                `All basic features`,
                `Priority search placement`,
                `10x Visibility boost`,
                `Unlimited Featured posts`,
                `10 Keywords`,
                `Expanded portfolio`,
                `Analytics & insights`,
              ]}
              // listItemsValue={[
              //   `${getPremiumPackageDetail?.regularListing}`,
              //   `${getPremiumPackageDetail?.featureListing}`,
              //   `${getPremiumPackageDetail?.noOfImages}`,
              // ]}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroImage;
