/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  AboutUs,
  Certifications,
  Associations,
  Social,
  Blogs,
  CustomerDashboard,
  Dashboard,
  Home,
  Notifications,
  Messages,
  Pricing,
  PrivacyPolicy,
  // SellerProfile,
  ServiceDetail,
  Services,
  TermsConditions,
  HelpCenter,
  UnAuthorized,
  PaymentDetails,
  Draft,
  Published,
  Bin,
  ResetPassword,
  NotificationSettings,
} from "./ComponentFile";
import Spinner from "./components/common/Spinner";
import Layout from "./components/Layout";
import RequireAuth from "./components/RequireAuth";
import useAuth from "./Hooks/useAuth";
import { useBusinessCategories } from "./Hooks/useBusinessCategories";
import { usePricingPlan } from "./Hooks/usePricingPlans";
import { useServiceTags } from "./Hooks/useServiceTags";
import Review from "./pages/BusinessDashboard/Inbox/Review";
import Portfolio from "./pages/BusinessDashboard/Settings/Portfolio";
import Login from "./sections/Login/Login";
import { SignUp } from "./sections/SignUp/SignUp";
import PricingPlans from "./pages/BusinessDashboard/Subscriptions/PricingPlans";
import SelectedCity from "./pages/Popular-cities/SelectedCity";
import PosterProfile from "./pages/PosterProfile";
import BlogTemplate from "./pages/BlogTemplate";
import CreatePost from "./pages/BusinessDashboard/Posting/CreatePost";
import Tradinghours from "./pages/BusinessDashboard/Settings/Tradinghours";
import Payment from "./pages/BusinessDashboard/Settings/Payment";
import CustomerResetPassword from "./pages/CustomerDashboard/Settings/CustomerResetPassword";
import CustomerAccount from "./pages/CustomerDashboard/Settings/CustomerAccount";
import CustMessages from "./pages/CustomerDashboard/Inbox/CustMessages";
import CustNotifications from "./pages/CustomerDashboard/Inbox/CustNotifications";
import CustReviews from "./pages/CustomerDashboard/Inbox/CustReviews";
import CustomerNotifications from "./pages/CustomerDashboard/Settings/CustomerNotifications";
import Posts from "./pages/CustomerDashboard/Posts";
import CustMobile from "./pages/CustomerDashboard/CustMobile";
import CustDeactivate from "./pages/CustomerDashboard/CustDeactivate";
import CustomerEmail from "./pages/CustomerDashboard/Settings/CustomerEmail";
import Deactivation from "./pages/BusinessDashboard/Deactivation";
import BusinessContact from "./pages/BusinessDashboard/Settings/BusinessContact";
import HowItWorks from "./pages/HowItWorks";
import ServiceCostCal from "./pages/ServiceCostCal";
import CustomerSupport from "./pages/CustomerSupport";
import BusinessSupport from "./pages/BusinessSupport";
import AllServices from "./pages/AllServices";
import ServiceGuide from "./pages/ServiceGuide";
import HelpCenterSection from "./sections/HelpCenter/sections/HelpCenterSection";
import HelpCenterLayout from "./sections/HelpCenter/HelpCenterLayout";
import HelpCenterDetails from "./sections/HelpCenter/sections/HelpCenterDetails";
import ContactUSForms from "./sections/HelpCenter/Forms/ContactUsForms";
import Accounts from "./pages/BusinessDashboard/Settings/Accounts";
import Following from "./pages/CustomerDashboard/Following";
import BlogCategories from "./pages/BlogCategories";
import ProTags from "./pages/ProTags";
import CouncilRagulators from "./pages/CouncilRagulators";
import BusinessStories from "./pages/BusinessStories";
import BusinessSuccessStories from "./pages/BusinessSuccessStories";

const Roles = {
  ADMIN: "a69f063d-e59b-4831-8c58-726cb245f2e5",
  SELLER: "3956fca6-e19b-4fd6-9833-4a98476406f4",
  CUSTOMER: "70c405e1-8d9d-487e-b1c6-508253359bdd",
};

function App() {
  const { status, data, isFetching } = usePricingPlan();
  const {
    status: businessCategoryStatus,
    isFetching: businessCategoryIsFetching,
  } = useBusinessCategories();
  const { status: serviceTagsStatus, isFetching: serviceTagsIsFetching } =
    useServiceTags();
  const [SignIn, setSignIn] = useState(false);
  const [SignUpState, setSignUpState] = useState(false);
  // let [CookieModelState, setCookieModelState] = useState(true);
  const location = useLocation();
  const { setAuth } = useAuth();

  useEffect(() => {
    var currentDate = new Date();
    const login = JSON.parse(localStorage.getItem("LoginSession"));
    var expirationDate = login?.expireAt;

    if (Date.parse(currentDate) < Date.parse(expirationDate)) {
      //normal application behaviour => session is not expired
      setAuth(login);
    } else {
      //redirect users to login page or whatever logic you have in your app
      //and remove the sessionStorage because it will be set again by previous logic
      localStorage.removeItem("LoginSession");
      setAuth(null);
    }

    const open = () => {
      setSignIn(true);
    };
    setTimeout(open, 300);
    return () => {
      clearTimeout(open);
      setSignIn(false);
      setSignUpState(false);
    };
  }, [location, setAuth]);
  // { path: '/seller-profile', name: 'Profile', element: List, exact: true },
  //   { path: '/seller-profile/profile-form', name: 'ProfileForm', element: Profiling, exact: true },
  //   { path: '/seller-profile/profile-form/:id', name: 'ProfileForm', element: Profiling },
  if (businessCategoryStatus === "loading" || status === "loading") {
    return <Spinner />;
  }
  return (
    <>
      <ScrollTop />
      <div className="font-main text-text-black App">
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* public routes */}
            <Route path="/" element={<Home />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="terms-&-conditions" element={<TermsConditions />} />
            <Route path="councils-regulations" element={<CouncilRagulators />} />
            <Route path="HelpCentre" element={<HelpCenterLayout />}>
              <Route index element={<HelpCenter />} />
              <Route path="article/:section" element={<HelpCenterDetails />} />
              <Route
                path=":section/:subsection"
                element={<HelpCenterSection />}
              />
              <Route path=":section" element={<HelpCenterSection />} />
              <Route path=":contact-us" element={<ContactUSForms />} />
            </Route>

            <Route path="about-us" element={<AboutUs />} />
            <Route path="pricing-plans" element={<Pricing />} />
            <Route path="how-it-works" element={<HowItWorks />} />
            <Route path="pro" element={<ProTags />} />
            <Route path="blogs" element={<Blogs />} />
            <Route path="blogs/category-name" element={<BlogCategories />} />
            <Route path="cost-estimation" element={<ServiceCostCal />} />
            <Route
              path="customer-support-centre"
              element={<CustomerSupport />}
            />
            <Route
              path="business-support-centre"
              element={<BusinessSupport />}
            />
            <Route path="business-stories" element={<BusinessStories />} />
            <Route
              path="business-success-stories"
              element={<BusinessSuccessStories />}
            />
            <Route
              path="services-guide"
              element={<AllServices />}
            />
            <Route
              path="blogs/article-id-will-be-here"
              element={<BlogTemplate />}
            />
            <Route
              path="/Services/available-categories-in-the-selected-city"
              element={<SelectedCity />}
            />
            {/* <Route path="/review-guidelines" element={<ReviewGuide />} /> */}
            <Route path="/poster-profile/:id" element={<PosterProfile />} />
            <Route path="/poster-profile" element={<PosterProfile />} />

            <Route
              path="/Login"
              element={
                <>
                  <Login
                    SignIn={SignIn}
                    setSignIn={setSignIn}
                    SignUpState={SignUpState}
                    setSignUpState={setSignUpState}
                  />
                  <SignUp
                    SignUpState={SignUpState}
                    setSignUpState={setSignUpState}
                    SignIn={SignIn}
                    setSignIn={setSignIn}
                  />
                </>
              }
            />
            <Route path="services/:slug" element={<Services />} />
            <Route path="services" element={<Services />} />
            <Route path="service-detail" element={<ServiceDetail />} />
            <Route path="service-detail/:id" element={<ServiceDetail />} />
            <Route path="services-guide/:slug" element={<ServiceGuide />} />
            <Route path="unauthorized" element={<UnAuthorized />} />

            {/* Protected  Routes */}
            <Route
              element={
                <RequireAuth allowedRoles={[Roles.ADMIN, Roles.CUSTOMER]} />
              }
            >
              <Route
                path="customer-dashboard/dashboard"
                element={<CustomerDashboard />}
              />
              <Route
                path="customer-dashboard/dashboard:userId"
                element={<CustomerDashboard />}
              />
              <Route path="customer-dashboard/posts" element={<Posts />} />
              <Route
                path="customer-dashboard/following"
                element={<Following />}
              />
              <Route
                path="customer-dashboard/settings/mobile"
                element={<CustMobile />}
              />
              <Route
                path="customer-dashboard/settings/email"
                element={<CustomerEmail />}
              />
              <Route
                path="customer-dashboard/account-deactivation"
                element={<CustDeactivate />}
              />
              <Route
                path="customer-dashboard/account"
                element={<CustomerAccount />}
              />
              <Route
                path="customer-dashboard/settings/notification-preferences"
                element={<CustomerNotifications />}
              />
              <Route
                path="customer-dashboard/settings/reset-password"
                element={<CustomerResetPassword />}
              />
              <Route
                path="customer-dashboard/inbox/chats"
                element={<CustMessages />}
              />
              <Route
                path="customer-dashboard/inbox/notifications"
                element={<CustNotifications />}
              />
              <Route
                path="customer-dashboard/inbox/reviews"
                element={<CustReviews />}
              />

              {/* <Route path="CustomerReview" element={<Reviews />} />
              <Route path="CustomerReview/:userId" element={<Reviews />} /> */}
            </Route>
            {/* old routes */}
            {/* <Route path="SellerProfile" element={<SellerProfile />} />
            <Route path="SellerProfile/:id/:name" element={<SellerProfile />} /> */}

            <Route
              element={
                <RequireAuth allowedRoles={[Roles.ADMIN, Roles.SELLER]} />
              }
            >
              <Route
                path="business-dashboard/dashboard"
                element={<Dashboard />}
              />
              <Route
                path="business-dashboard/settings/account"
                element={<Accounts />}
              />
              <Route
                path="business-dashboard/account-deactivation"
                element={<Deactivation />}
              />
              <Route
                path="business-dashboard/subscriptions/pricing-plans"
                element={<PricingPlans />}
              />
              <Route
                path="business-dashboard/subscriptions/billing-details"
                element={<PaymentDetails />}
              />
              <Route
                path="business-dashboard/posts/create-post"
                element={<CreatePost />}
              />
              <Route
                path="business-dashboard/posts/drafts"
                element={<Draft />}
              />
              <Route
                path="business-dashboard/posts/drafts/:Id/:type"
                element={<Draft />}
              />
              <Route
                path="business-dashboard/posts/published"
                element={<Published />}
              />
              <Route
                path="business-dashboard/posts/published/:Id/:type"
                element={<Published />}
              />
              <Route path="business-dashboard/posts/bin" element={<Bin />} />
              <Route
                path="business-dashboard/posts/bin/:Id/:type"
                element={<Bin />}
              />
              <Route
                path="business-dashboard/inbox/chats"
                element={<Messages />}
              />
              <Route
                path="business-dashboard/inbox/notifications"
                element={<Notifications />}
              />
              <Route
                path="business-dashboard/inbox/reviews"
                element={<Review />}
              />
              <Route
                path="business-dashboard/settings/certifications"
                element={<Certifications />}
              />
              <Route
                path="business-dashboard/settings/associations"
                element={<Associations />}
              />
              <Route
                path="business-dashboard/settings/social-profiles"
                element={<Social />}
              />
              <Route
                path="business-dashboard/settings/trading-hours"
                element={<Tradinghours />}
              />
              <Route
                path="business-dashboard/settings/business-contact"
                element={<BusinessContact />}
              />
              <Route
                path="business-dashboard/settings/payment-options"
                element={<Payment />}
              />
              <Route
                path="business-dashboard/settings/notification-preferences"
                element={<NotificationSettings />}
              />
              <Route
                path="business-dashboard/settings/reset-password"
                element={<ResetPassword />}
              />
              <Route
                path="business-dashboard/settings/manage-portfolio"
                element={<Portfolio />}
              />
            </Route>
          </Route>
        </Routes>
        {/* <AcceptCookies
          initialIsOpen={CookieModelState}
          setCookieModelState={setCookieModelState}
        /> */}
      </div>
    </>
  );
}
export default App;

export const ScrollTop = () => {
  const location = useLocation();
  useEffect(() => {
    if (location.pathname !== "/" && location.hash === "") {
      const time = setTimeout(() => {
        window.scrollTo(0, 0);
        clearTimeout(time);
      }, 200);
    }
  }, [location]);
  return null;
};
