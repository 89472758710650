import React from "react";
import { Link } from "react-router-dom";
import BlogCard from "../../components/common/BlogCard/BlogCard";
import MainButton from "../../components/common/MainButton/MainButton";
import Heading from "../../components/common/Typography/Heading";

const BlogsData = [
  {
    link: "",
    blogName:
      "Blog Name list of service providers and select the best service on the basis of reviews",
    text: "Go select the best service on the basis of reviews. Go through the list of service providers and select the best service on the ",
  },
  {
    link: "",
    blogName:
      "Blog Name list of service and select the best service on the basis of reviews",
    text: "Go through the list of service providers select  of reviews. Go through the list of service providers and select select the best service on the basis of reviews. ",
  },
  {
    link: "",
    blogName:
      "Blog Name list of service and select the best service on the basis of reviews",
    text: "Go through the list of service providers and select of reviews. Go through the list of service ",
  },
  // {
  //   link: "",
  //   blogName:
  //     "Blog Name list of service providers and select the best service on the basis of reviews",
  //   text: "Go through the list of service providers and select the best service on the basis of reviews. select the best service on the basis of reviews. Go through the list of service providers and select the best service on the basis of reviews. select the best service on the basis of reviews.  ",
  // },
];
const BlogsDataMobile = [
  {
    link: "",
    blogName:
      "Blog Name list of service providers and select the best service on the basis of reviews",
    text: "Go select the best service on the basis of reviews. Go through the list of service providers and select the best service on the ",
  },
  {
    link: "",
    blogName:
      "Blog Name list of service and select the best service on the basis of reviews",
    text: "Go through the list of service providers select  of reviews. Go through the list of service providers and select select the best service on the basis of reviews. ",
  },
  {
    link: "",
    blogName:
      "Blog Name list of service and select the best service on the basis of reviews",
    text: "Go through the list of service providers and select of reviews. Go through the list of service ",
  },
  {
    link: "",
    blogName:
      "Blog Name list of service providers and select the best service on the basis of reviews",
    text: "Go through the list of service providers and select the best service on the basis of reviews. select the best service on the basis of reviews. Go through the list of service providers and select the best service on the basis of reviews. select the best service on the basis of reviews.  ",
  },
];

const Blogs = () => {
  return (
    <>
      <div className="bg-pastalblue pt-10 sm:pt-20 xl:pt-40">
        <div>
          <div className="mx-[5%] xl:mx-auto xl:w-[1100px] 2xl:w-[1264px]">
            <div className="flex items-center justify-between">
              <div>
                <Heading variant={"h2"} className={"text-offblack"}>
                Blog, Articles, and Updates
                </Heading>
              </div>
              <div className="ml-5">
                <Link to={`/blogs`}>
                  <MainButton variant={"small"}>Blog</MainButton>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:hidden flex justify-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 mx-[5%] xl:mx-auto xl:w-fit text-ellipsis overflow-hidden h-[550px] sm:h-auto gap-8 mt-10 xl:mt-20">
            {BlogsDataMobile.map((e, index) => {
              return (
                <BlogCard
                  link={e.link}
                  key={index}
                  blogName={e.blogName.split(/\s+/).slice(0, 9).join(" ")}
                  text={e.text}
                  blogTitle={e.blogTitle}
                />
              );
            })}
          </div>
        </div>
        <div className="hidden lg:flex justify-center">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 lg:mx-auto lg:w-[900px] xl:w-fit text-ellipsis overflow-hidden h-[550px] sm:h-auto gap-8 mt-7 sm:mt-10 xl:mt-20">
            {BlogsData.map((e, index) => {
              return (
                <BlogCard
                  link={e.link}
                  key={index}
                  blogName={e.blogName.split(/\s+/).slice(0, 9).join(" ")}
                  text={e.text}
                  blogTitle={e.blogTitle}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default Blogs;
