import React, { useState, useEffect } from "react";
import "../../../index.css";
// import Heading from "../Typography/Heading";
import { useLocation } from "react-router-dom";
// import SecondaryHeading from "../Typography/SecondaryHeading";
import SpecialText from "../Typography/SpecialText";
import AuxiliaryText from "../Typography/AuxiliaryText";

export default function RangeSlider({
  units,
  starting,
  Maximum,
  values,
  ...restProps
}) {
  const location = useLocation();

  useEffect(() => {
    const sliderEl = document.querySelector("#range2");
  
    const getColors = () => {
      const width = window.innerWidth;
      if (width < 1024) {
        return ['#2E00FF', '#FFFFFF']; // Mobile colors
      } else {
        return ['#2E00FF', '#E8F0FF']; // Desktop colors
      }
    };
  
    const updateSliderBackground = (value) => {
      const [color1, color2] = getColors();
      const progress = (value / sliderEl.max) * 100;
      sliderEl.style.background = `linear-gradient(to right, ${color1} ${progress}%, ${color2} ${progress}%)`;
    };
  
    // Initialize slider background
    const initialSliderValue = values; // Assuming 'blue' is defined elsewhere
    updateSliderBackground(initialSliderValue);
  
    sliderEl.addEventListener("input", (event) => {
      updateSliderBackground(event.target.value);
    });
  
    // Update on resize
    const handleResize = () => {
      updateSliderBackground(sliderEl.value); // Update colors on resize
    };
  
    window.addEventListener("resize", handleResize);
  
    return () => {
      sliderEl.removeEventListener("input", (event) => {
        updateSliderBackground(event.target.value);
      });
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  
  return (
    <>
      {location.pathname.includes("SellerDashboard/Posting/CreatePost") ? (
        <label className="value2 mb-1 mt-2 flex justify-center text-center">
          <AuxiliaryText variant={"Placeholder"} className={"text-offblack"}>
            {values} {units}
          </AuxiliaryText>
        </label>
      ) : (
        <SpecialText variant={"LargeNumber"} className={"flex justify-center text-offblack"}>
          {values} {units}
        </SpecialText>
      )}
      <div className="range">
        <input
          {...restProps}
          type="range"
          min={"1"}
          max={Maximum}
          id="range2"
        />
      </div>
    </>
  );
}
