import React, { useEffect, useState } from "react";
import Account from "../../../sections/BusinessDashboard/Settings/Account";
import Heading from "../../../components/common/Typography/Heading";
import DashboardMainContainer from "../../../components/common/Containers/DashboardMainContainer";
import { Formik, Form } from "formik";
import {
  defaultValues,
  validationSchema,
} from "../../../FormValidations/Latest/BusinessAccount";

const Accounts = () => {
  // Set initial values on mount
  const [initialValues, setInitialValues] = useState(defaultValues);

  useEffect(() => {
    const savedData =
      JSON.parse(localStorage.getItem("formData")) || defaultValues;
    setInitialValues(savedData);
  }, []);

  const handleSubmit = (values) => {
    console.log("Form data:", values);
  };
  return (
    <>
      <DashboardMainContainer className={"lg:px-5 lg:pb-10 pt-[33px]"}>
        <Heading variant={"h6"} className={"text-center text-offblack"}>
          Manage account
        </Heading>
        <div className="flex justify-center mt-[60px] min-h-screen">
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            enableReinitialize // Ensures reinitialization of form values on step change
          >
            {({ setFieldValue, values }) => (
              <Form>
                <Account values={values} setFieldValue={setFieldValue} />
              </Form>
            )}
          </Formik>
        </div>
      </DashboardMainContainer>
    </>
  );
};

export default Accounts;
