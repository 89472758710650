import * as Yup from "yup";

export const defaultValues = {
  selectedType: "",
  dropdown1: "",
  dropdown2: "",
  postTitle: "",
  postTags: [], // Ensure postTags is initialized as an empty array
  description: "",
  range: 10,
  location: "",
  portfolioImages: [],
};

export const validationSchema = Yup.object({
  dropdown1: Yup.object().required("Primary category is required"),
  dropdown2: Yup.object().required("Sub category is required"),
  postTitle: Yup.string()
    .min(5, "Atleast five letters required")
    .max(20, "Post title must be less than or equal to 100 characters")
    .required("Post title is required"),
  postTags: Yup.array()
    .min(1, "Minimum 1 tag is required")
    .max(20, "Maximum 20 Tags allowed")
    .required("Minimum 1 tag is required"), // Ensure tags are not empty
  portfolioImages: Yup.array().required("Please upload post images."),
  description: Yup.string()
    .min(120, "Minimum 120 characters required")
    .max(250, "Maximum 250 characters required")
    .required("Description can't be empty."),
  range: Yup.number().required("Please define radius."),
  location: Yup.string().required("Please share your location."),
});
